import React, {useEffect, useState} from 'react';
import css from './noticeNum.module.less';
import iconNotice from 'aliasimgurl/icon_notice.png';
import {useHistory} from "react-router-dom";

/** 消息通知 */
export const NoticeNum = (props)=>{
    const {push} = useHistory();
    const {num:{msgcount}={}} = props;
    const [noticeNum,setNoticeNum] = useState(0);
    const [modalFlag,setCloseModal] = useState(true);
    useEffect(()=>{
        console.log("num",props);
            setNoticeNum(msgcount);
    },[msgcount]);
    const closeModal = (e)=>{
        e.stopPropagation();
        setCloseModal(false);
    };
    return (
        <div className={ css.noticeNum } >
            {noticeNum >0 && modalFlag &&
               <a className={ css.box } href={'http://www.china-forest.com/account/work'} target={'_blank'}>
                   <img src={iconNotice} alt=""/>  您有新的消息通知 <span>{noticeNum >99? '99+':noticeNum}</span>
                   <div className={css.close} onClick={closeModal}>x</div>
               </a>
            }


        </div>
    );
}
