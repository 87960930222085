import React, {Fragment, useEffect, useState} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {YcPagination} from '../../../components';
import classNames from 'classnames';
import {Button, Form, Input} from 'antd';
import {useDataApi} from '../../../hook';
import css from './index.module.less';
import {useHistory} from "react-router-dom";
import {MODE_OFF} from "../../../common/static_number";

const {Search} = Input;
export const ProOpen = () => {


    const [params, setParams] = useState({page: 1, type: '1', total: 0});
    const [{data: dataSource, isLoading/*, isError */}, updateParams] = useDataApi({initialData: {data: []}});
    const {push} = useHistory();

    useEffect(() => {
        if (!isLoading) {
            const _params = params;
            _params.total = dataSource.count;
            setParams(_params);
        }
    }, [isLoading]);

    useEffect(() => {
        const {project_name, type, page} = params;
        updateParams({
            url: '/api/user/openTender',
            method: 'post',
            data: {bid_type: 1, page, type, search_name: project_name}
        })
    }, [params]);

    const handleSearch = (value) => {
        let _params = params;
        _params.project_name = value;
        _params.page = 1;
        setParams({..._params});
    };
    const handleStatusText = (opt) => {
        const {status} = opt;
        switch (status) {
            case 8:
                if (opt.is_set === 1) {
                    return <span>待开标</span>;
                } else {
                    return <span>设置开标口令</span>;
                }

            case 9:
                if (opt.is_set === 1) {
                    return <span>待开标</span>;
                } else {
                    return <span>设置开标口令</span>;
                }
            case 10:
                if (opt.is_set === 1) {
                    return <span>待开标</span>;
                } else {
                    return <span>设置开标口令</span>;
                }
            case 11:
                if (opt.is_set === 1) {
                    return <span>待开标</span>;
                } else {
                    return <span>设置开标口令</span>;
                }
            case 12:
                return <span>开标</span>;
            default:
                return <span>已开标</span>

        }
    };
    const menu = (opt) => {
        const {status, is_set} = opt;

        return (<Fragment>
            {(status === 8 || status === 9 || status === 10 || status === 11) && !is_set &&
            <div className={css.btn} onClick={() => push({
                pathname: '/operator/Command',
                state: {project_sn: opt.project_sn}
            })}>设置开标口令</div>}
            {status === 12 && <div className={css.btn} onClick={() => push({
                pathname: '/publish/listOpen',
                state: {project_sn: opt.project_sn, step: 1}
            })}>开标</div>}
        </Fragment>);
    };

    function projectNameChange(e) {
        params.project_name = e.target.value;
    }

    return (

        <div >
            <Card title="开标项目" cardStyle={{padding: '20px 0px'}}>
                <Form.Item wrapperCol={{span: 10}} style={{padding: '0 20px'}}>
                    <Search
                        placeholder="请输入项目名称/项目编号进行搜索"
                        enterButton="搜索"
                        size="large"
                        defaultValue={params.project_name}
                        onChange={projectNameChange}
                        onSearch={value => handleSearch(value)}
                    />
                </Form.Item>
                <div className={classNames(css.top, css.header)}>
                    <div className={css.name}>项目名称</div>
                    <div className={css.time} style={{width: '180px'}}>开标时间</div>
                    <div className={css.time}>状态</div>
                    <div className={css.operation} style={{width: '120px'}}>操作</div>
                </div>
                <div className={css.list}>
                    {!isLoading && dataSource.list.map(opt =>
                        <div className={css.row} key={opt.project_sn}>
                            <div className={classNames(css.top)}>
                                <div className={css.name}
                                     onClick={() => push({pathname: '/market/view/' + opt.project_sn})}>
                                    {opt.project_name || '未命名'}
                                </div>
                                {/*<div className={css.time}>{opt.status_text}</div>*/}


                                <div className={css.time} style={{width: '180px'}}>{opt.bidend_time}</div>
                                <div className={css.time}>
                                    {handleStatusText(opt)}
                                </div>
                                <div className={css.operation} style={{width: '120px'}}>
                                    {menu(opt)}
                                </div>
                            </div>
                            <div className={css.other}>
                                <div>项目编号：{opt.project_sn}</div>
                                <div>所属行业：{opt.industry_type}-{opt.industry_id}</div>
                                <div>招标类型：{opt.tendering_type}</div>
                                <div>招标方式：{opt.tendering_mode === MODE_OFF ? '仅线上发布招标公告' : '在线电子招投标'}</div>
                                {/*<div>项目类别：{opt.infor_type_text}</div>*/}
                                {/*<div>招标单位：{opt.store_id_text}</div>*/}
                                {
                                    opt.address && <div>所属地区：{opt.address}</div>
                                }
                            </div>
                        </div>
                    )}
                    <YcPagination
                        total={params.total || 0}
                        pageSize={8}
                        current={params.page}
                        onChange={(e) => {
                            const _params = params;
                            _params.page = e;
                            setParams({..._params})
                        }}
                        style={{margin: '30px 0'}}/>
                </div>
            </Card>
        </div>
    )
};