import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import css from './SubNav.module.less';
import {AppstoreOutlined, ClusterOutlined, SettingOutlined, SoundOutlined, StarOutlined} from '@ant-design/icons';
import {Badge} from "antd";
import {useMessageNumData} from "../../../hook/messageNumData";
import {useUserInfo} from "../../../hook";


export const SubNav = (props)=>{

    const subNav = [{
        label:'招采项目管理',
        path:'/account/project/list',
        icon: <AppstoreOutlined />,
    },{
        label:'投标项目管理',
        path:'/account/bid/list',
        icon: <ClusterOutlined />,
    },{
        label:'审核管理',
        path:'/account/project/audit',
        icon: <ClusterOutlined />,
    },{
        label:'保证金管理',
        path:'/account/deposit',
        icon: <ClusterOutlined />,
    },{
        label:'员工管理',
        path:'/account/employee',
        icon: <ClusterOutlined />,
    },{
        label:'收藏夹',
        path:'/account/collect',
        icon: <StarOutlined />,

    }];
    const [currentUrl,setCurrentUrl] = useState('');
    const [userInfo] = useUserInfo();
    const [noticeNum]=  useMessageNumData();
    useEffect(() => {
        setCurrentUrl(window.location.pathname)
    }, [window.location]);
    return (
        <div className={ css.navList }>
            {
                subNav.map((opt,index)=>
                    <div key={ index }>
                        {
                            (opt.label === '员工管理' && userInfo.is_managemen !== 2 ) ||   <NavLink to={ opt.path }
                                                                                   className={ opt.children ?css.nav: css.parentNav } activeClassName={ opt.children ? css.selected: css.selected}>
                                <div>
                                    <span>{opt.icon}  { opt.label }</span>
                                    {opt.label === '消息通知' && currentUrl !== opt.path && <Badge style={{padding:0}} count={noticeNum.msgcount ||0}  overflowCount={99} offset={[0,-2]}/>}
                                    {opt.label === '项目管理' && !currentUrl.includes(opt.path) && <Badge style={{padding:0}} count={noticeNum.opencount+noticeNum.commentcount+noticeNum.approvalcount || 0}  overflowCount={99} offset={[0,-2]}/>}
                                </div>
                            </NavLink>
                        }
                    </div>
                )
            }
        </div>
    )
}