import React, {Fragment, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom'
import {UploadImage,} from '../../components';
import {Descriptions, Modal} from 'antd';
import {useDataApi, useIsLogin, useUserInfo} from '../../hook';
import css from './index.module.less';
import {MODE_OFF, resourcesUrl} from "../../common/static_number";


export const PreviewMarket = (props) => {

    const [isLogin] = useIsLogin();
    const {project:data={}} = props;
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage] = useState(''); //默认的图片列表
    const [userInfo] = useUserInfo();
    const [{data:address,setData},updateParams] = useDataApi();

    useEffect(() =>{
        const {province_id,city_id,county_id} = data;
        if(data.address){
            updateParams({url: '/api/getRegion', method: 'post', data: {province_id,city_id,county_id}});
        }else{
            setData({address:data.address});
        }
        console.log("data",data);
    },[data]);

    const handleCancel = () => setPreviewVisible(false);

    return (
        <div className="">
            <div className={ css.innerMain }>
                <div className={ css.listWrap }>
                    <div className={ css.viewWrap }>
                        <div className={ css.titleInfo }>
                            <h2 className={ css.viewTitle }>{data.title}</h2>
                            <p>
                                <i className="iconfont iconweizhi" /><span>{address.address}</span>
                                {/*<i className="iconfont iconrili"/> <span>未发布</span>*/}
                            </p>
                        </div>
                        <div className={ css.extInfo }>
                            <table>
                                <tbody>
                                        <th>公告名称</th>
                                        <td >{data.title}</td>
                                        <th>项目编号</th>
                                        <td>{data.project_sn}</td>
                                        <th>项目名称</th>
                                        <td>{data.project_name}</td>
                                        <th>行业分类</th>
                                        <td>{data.category_trade} - {data.category_name}</td>
                                        <th>招标单位</th>
                                        <td>{userInfo.store_name}</td>
                                        <th>项目金额</th>
                                        <td>
                                            {data.starting_price} (万元)
                                        </td>

                                        <th>招标类型</th>
                                        <td>{data.tendering_type === '1' ?'公开招标':'邀请招标'}</td>
                                        <th>招标方式</th>
                                        <td>
                                            {data.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标'}
                                        </td>

                                        <th>联系人</th>
                                        <td>
                                            {data.user_name}
                                        </td>
                                        <th>联系电话</th>
                                        <td>
                                            {
                                                isLogin?
                                                    <span >{data.user_phone}</span>:
                                                    <span>
                                                    <NavLink to="/login">登录</NavLink>
                                                    后可查看
                                                </span>
                                            }
                                        </td>
                                        <th>所属地区</th>
                                        <td>{address.address}</td>
                                        <th></th>
                                        <td></td>
                                        {/*{data.infor_type ==='中标公示' &&  <Fragment>*/}
                                        {/*    <th>成交时间</th>*/}
                                        {/*    <td>{ data.completetime}</td>*/}
                                        {/*</Fragment>}*/}

                                </tbody>
                            </table>
                        </div>
                        <div className={ css.viewCon } dangerouslySetInnerHTML={{__html:data.content}}/>
                        <Descriptions style={{marginTop: '20px'}} column={1}>
                            {data.file  &&  <Descriptions.Item label='附件'>
                                {  data.file.map((item)=>
                                    <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                )}
                            </Descriptions.Item>}
                            {data.images &&
                            <Descriptions.Item label='图片'>
                                <UploadImage disabled={true} uploadNum={3} value={data.images || []}/>
                                <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                </Modal>
                            </Descriptions.Item> }
                        </Descriptions>
                    </div>
                </div>
            </div>
        </div>
    )
}