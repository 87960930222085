import React, {useEffect} from 'react';
import {Button, Descriptions, message} from 'antd';
import css from './expert-detail.module.less'
import {tap} from "rxjs/operators";
import {useDataApi} from "../../hook";
import {ExpertModal} from "../../common";

export const ExpertDetail = (props)=>{
    const [{data,setData,isLoading},updateParams] = useDataApi();
    const sendInvite = () => {
        console.log();
        const {expert_id,project_sn} = props.data;
        ExpertModal.sendInvite({expert_id,project_sn,expert_name:data.username}).pipe(
            tap(res => console.log(res))
        ).subscribe({
            next(res) {
                console.log("发送成功！");
                message.success('发送成功');
                const _data = data;
                _data.bstatus = 1;
                props.callback();
                setData({..._data});
            }
        });
    }
    useEffect(()=>{
        const {expert_id,project_sn} = props.data;
        updateParams({url: '/api/expertDetail',data:{expert_id,project_sn}, method: 'post'})
    },[props]);
    return (
        <div className={css.detail}>
            {
                !isLoading && <Descriptions column={2} title={'基本信息'} bordered >
                    <Descriptions.Item label="姓名">{data.username}</Descriptions.Item>
                    <Descriptions.Item label="性别">{data.gender === 0 ? '男':'女'}</Descriptions.Item>
                    <Descriptions.Item label="联系方式">{data.mobile}</Descriptions.Item>
                    <Descriptions.Item label="身份信息">{data.legal_identity}</Descriptions.Item>
                    {/*<Descriptions.Item label="工作单位">{data.workplace}</Descriptions.Item>*/}
                    <Descriptions.Item label="职称">{data.title}</Descriptions.Item>
                    {/*<Descriptions.Item label="评定时间">{data.evaluate_time}</Descriptions.Item>*/}
                    <Descriptions.Item label="评审地域">{data.bidaddress}</Descriptions.Item>
                    <Descriptions.Item label="评定行业" style={{width:'200px'}}>{
                        data.industry.map((item,index)=>{
                            if(!index){
                                return item.industry_type_name +'-'+ item.industry_id_name
                            }
                            return ','+ item.industry_type_name +'-'+ item.industry_id_name
                        })
                    }
                    </Descriptions.Item>

                    {/*<Descriptions.Item label="最高学历">{data.education}</Descriptions.Item>*/}
                    {/*<Descriptions.Item label="所学专业">{data.major}</Descriptions.Item>*/}
                    <Descriptions.Item label="专业工龄">{data.major_age }年</Descriptions.Item>
                </Descriptions>
            }

            {data.bstatus === 0 && <Button type={"primary"} style={{margin: '10px auto auto'}} onClick={sendInvite} >发送邀请</Button>}
        </div>
    )
}