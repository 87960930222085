import {CService} from "./services";

// import { parse, stringify } from "qs/qs";

export class Service extends CService {
    baseUrl = "";
    devUrl = "";
    domain = "";
    openidName = "token";
    version = "";
    env = "dev";

    doLogin(data){
        return this.POST('login',data);
    }

    doLogout(data){
        return this.POST('logout',data);
    }

    doRegister(data){
        return this.POST('register',data);
    }

    getSendCode(data){
        return this.POST('sendCode',data);
    }
    /** 验证手机号码是否存在 */
    verifyMobile(data){
        return this.POST('user/getmobile',data);
    }
    /**
    *重置密码
    */
    doResetpwd(data){
        return this.POST('resetpwd',data);
    }

    /** 保存意见 */
    saveIdea(data){
        return this.POST('addProposal',data);
    }


    getSigns(data){
        return this.POST('getsigns',data);
    }

    getCityData(data){
        return this.POST('getCity',data);
    }

    getUserInfo(data){
        return this.POST('userIndex',data);
    }

    /** 更新头像 */
    updateAvatar(data){
        return this.POST('profile',data);
    }

    doChangeMobile(data){
        return this.POST('changemobile',data);
    }

    doUpdateProfile(data){
        return this.POST('profile',data);
    }

    /**
    *找回密码
    */
    doRetrievepwd(data){
        return this.POST('retrievepwd',data);
    }

    getNoticeNum(data){
        return this.POST('allNoticeNum',data);
    }

    /** 身份认证 */
    authForm(data){
        return this.AXIOS({url:'brokerOpenStore',data,headers:{'Content-Type':"application/json;charset=utf-8"}});

    }

    /* 文件上传相关接口 */
    fetchUploadToken(data){
        return this.AXIOS({url:'upToken',data});
    }

    /* 发布招标信息接口 --开始  */
    /** 创建项目  */
    createPro(data){
        return this.POST('addProject',data);
    }
    /** 删除项目  */
    delProject(data){
        return this.POST('delProject',data);
    }
    /** 验证项目标题是否重名 */
    checkTitle(data){
        return this.POST('checkTitle',data);
    }
    /** 设置经办人 */
    setResponsible(data){
        return this.AXIOS({url:'setApproval',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }

    /** 保存公告 */
    saveNoticePublish(data){
        return this.AXIOS({url:'projectAdd',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }

    /*start 采购开始 start*/
    /** 保存采购信息 */
    savePurchase(data){
        return this.AXIOS({url:'addPurchase',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }
    /** 保存采购信息 */
    projectOffer(data){
        return this.AXIOS({url:'projectOffer',data,headers:{'Content-Type':"application/json;charset=utf-8"}});

    }
    /*end 采购结束 end*/

    /** 保存公告 */
    noticeApply(data){
        return this.POST('signBid',data);
    }
    /** 收藏 */
    collect(data){
        return this.POST('addCollect',data);
    }
    /** 取消收藏 */
    cancelCollect(data){
        return this.POST('delCollect',data);
    }

    /** 增加下载数量 */
    downloadAdd(data){
        return this.POST('addNum',data);
    }

    /** 邀请供应商提交审批 */
    saveNoticeInvitation(data){
        return this.AXIOS({url:'invitationStore',data,headers:{'Content-Type':"application/json;charset=utf-8"}})
    }

    /* 发布招标信息接口 --开始  */
    /** 保存资质审批结果 */
    saveQualificationAudit (data){
        return this.AXIOS({url:'upSign',data,headers:{'Content-Type':"application/json;charset=utf-8"}})
    }

    /** 保存审批结果 */
    saveAudit(data){
        return this.AXIOS({url:'user/handleApproval',data,headers:{'Content-Type':"application/json;charset=utf-8"}})
    }

    /** 撤回审批 */
    repealAudit(data){
        return this.POST('delsupplyStore',data);
    }

    /** 发布公告 */
    publish(data){
        return this.POST('release',data);
    }
    /** 提交入围名单 */
    signApproval (data){
        return this.POST('signApproval',data);
    }
    /** 发送通知 */
    sendInform (data){
        return this.AXIOS({url:'signResult',data,headers:{'Content-Type':"application/json;charset=utf-8"}})
    }


    /** 保存招标文件 */
    saveWriteFile(data){
        return this.POST('writeFile',data);
    }

    /** 发布招标文件 */
    sendFile(data){
        return this.POST('sendFile',data);
    }

    /** 签收招标文件 */
    signFile(data){
        console.log("data",data);
        return this.POST('signforFile',data);
    }

    /** 上传缴纳凭证 */
    payDeposit(data){
        return this.AXIOS({url:'payBond',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }

    /** 发送招标文件 */
    bidSend(data){
        return this.AXIOS({url:'bidSend',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }

    /** 开标 */
    open(data){
        return this.POST('compareKey',data);
    }
    /** 设置开标口令 */
    setSecretkey(data){
        return this.POST('setSecretkey',data);
    }
    /** 获取开标人手机号 */
    getOpenMobile (data){
        return this.POST('getmobile',data);
    }
    /** 重置开标口令 */
    resetSecretkey(data){
        return this.POST('retrieveKey',data);
    }
    /** 设置评标时间 */
    setBidtime(data){
        return this.AXIOS({url:'setBidtime',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }
    /** 验证可否开标 */
    validateOpen(data){
        return this.POST('openbid',data);
    }
    /** 保存分数 */
    scoring(data){
        return this.POST('scoring',data);
    }
    /** 提交中标人 */
    scaling(data){
        return this.AXIOS({url:'scaling',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }
    /** 发布中标通知 */
    sendInformSuccess (data){
        return this.AXIOS({url:'scalingMsg',data,headers:{'Content-Type':"application/json;charset=utf-8"}})
    }
    /** 保存定标公告 */
    saveNoticeConfirm(data){
        return this.AXIOS({url:'winningBid',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }

    /** 接收中标结果 */
    choice(data){
        return this.POST('choice',data);
    }

    /** 发布中标公示 */
    winBid(data){
        return this.POST('winBid',data);
    }

    /** 接受邀请 */
    invitationMsg(data){
        return this.POST('invitationMsg',data);
    }

    /** 回复 */
    submitReply(data){
        return this.AXIOS({url:'consultation',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }
    /** 提交评分 */
    submitCommend(data){
        return this.POST('submit',data);
    }

    /** 进入定标阶段 */
    goConfirm(data){
        return this.POST('calibration',data);
    }

    /** 更新消息状态  */
    updateNotice(data){
        return this.POST('user/msgStatus',data);
    }

    /** 更新系统消息状态  */
    resetMessage(data){
        return this.POST('user/upmsgStatus',data);
    }

    /*start 员工管理 start*/

    /** 添加员工 */
    addEmployee(data){
        return this.POST('addStaff',data);
    }

    /** 删除员工 */
    delEmployee(data){
        return this.POST('deluser',data);
    }

    /** 改变员工状态 */
    employeeFlag(data){
        return this.POST('upStatus',data);
    }

    /*end 员工管理 end*/


    /*start 专家接口 start*/
    register(data){
        return this.AXIOS({url:'register',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }
    /** 设置抽取规则 */
    setRules(data){
        return this.POST('extract',data);
    }
    /** 设置抽取规则 */
    sendInvite(data){
        return this.POST('sendInvitation',data);
    }
    /** 设置抽取规则 */
    accept(data){
        return this.POST('accept',data);
    }
    /*end 专家接口 end*/

}

export default new Service();
// Service.model = new Service()