import React, {useEffect, useState} from "react";
import css from "./edit.module.less";
import {Button, DatePicker, Form, Input, message, Modal} from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import {UploadFile} from "../../../../components";
import {AUDIT_SEND} from "../../../../common/static_number";
import {PublishModal} from "../../../../common";
import {useDataApi} from "../../../../hook";
import moment from "moment";
import {tap} from "rxjs/operators";
import {useHistory} from "react-router-dom";
import {Contacts} from "../../components";

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10},
};

export const Edit = (props) => {
    const {params:{project_sn=''}={} } = props;
    const readOnly = false;
     const [ form ] = Form.useForm();
    const [params,setParams] = useState({});
    let { push } = useHistory();
    const [users, setUsers] = useState([]); //选中的审批人
    const [contactsVisible, showContactsVisible] = useState(false); //审批人弹窗控制
    const [initialData, setInitialData] = useState({});

    const [{data, isLoading}] = useDataApi({url: '/api/issuedDetail', method: 'post', data: {type:AUDIT_SEND,project_sn}});
    useEffect(() => {
        if (!isLoading) {
            const _data = data.project_issued ||{};
            const _param = params;
            _data.bidstart_time = _data.bidstart_time?moment(_data.bidstart_time):null;
            _data.bidend_time =  _data.bidstart_time?moment(_data.bidend_time):null;
            _param.attachment_url = _data.attachment_url || [];

            setParams(_param);
            setEditor(BraftEditor.createEditorState(_data.content));
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);

    const [editor,setEditor] = useState();
    const excludeControls = ['media', 'emoji'];
    /** 富文本内容改变事件 */
    const handleEditorChange = (value) => {
        setEditor(value);
    };
    /** 提交验证 */
    const submitVerify = () => {
        form.validateFields().then((values) => {
            if (!editor.toHTML()) {
                return
            }
            showContactsVisible(true);
        })


    };
    let Loading = false;
    /** 提交事件 */
    const submit = ({is_approval = 0}) => {
        if (Loading) {
            message.warn('正在提交，不要心急哟~');
            return
        }
        const values =  form.getFieldsValue(['issued_name','bidend_time','bidstart_time']);
        console.log('======',values);
        let _params = Object.assign({...values}, {...params});
        _params.content = encodeURI(editor.toHTML());
        _params.bidend_time = _params.bidend_time ? _params.bidend_time.format('YYYY-MM-DD HH:mm') : '';
        _params.bidstart_time = _params.bidend_time ? _params.bidstart_time.format('YYYY-MM-DD HH:mm') : '';

        PublishModal.saveWriteFile({is_approval,project_sn,is_change:1, ..._params}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                Loading = false;
                if(is_approval === 0){
                    message.success('保存成功！',3);
                }else{
                    message.success('提交成功！',3, push({pathname: '/alteration',state:{project_sn,step:2}}));
                };
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    /** 文件上传成功事件 */
    const handleFileChange = (val) => {
        let _params = params;
        _params.attachment_url = val;
        setParams(_params);
    };
    /** 审批人弹窗选择完成 */
    const handleContactsOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        showContactsVisible(false);
        const audit = users[0];

        PublishModal.setResponsible({
            type: AUDIT_SEND, project_sn, is_approval:1, ...audit
        }).then(({data: {data, msg, code}}) => {
            if (code === 200) {
                submit({is_approval: 1});
            } else {
                message.error(msg)
            }
        })
    };
    /** 人员选择回调函数 */
    const contactsSuccess = e => {
        setUsers(e.map(item => {
            return {username: item.title, user_id: item.id}
        }));
    };
    /** 关闭审批人弹窗 */
    const handleCancel = e => {
        showContactsVisible(false);
    };
    return (
        <div className={css.edit}>
            <div className={css.boxTitle}>
                编辑招标文件变更
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>
            {!initialData.isLoading ||
            <Form
                {...layout}
                initialValues={initialData}
                scrollToFirstError = { true }
                form={form}
            >
                <Form.Item
                    labelCol={{span: 3}}
                    wrapperCol={{span: 10}}
                    label="招标文件标题"
                    name="issued_name"
                    rules={[{required: true, message: '请填写招标文件标题'},{min: 6,message: '招标文件标题最少为6个字符'},{max: 50,message:'招标文件标题最长为50个字符'}]}
                >
                    <Input placeholder='请填写招标文件标题' autoComplete="off"/>
                </Form.Item>
                <Form.Item label="投标时间" wrapperCol={{span: 12}} style={{marginBottom: '0'}} required
                >
                    <Form.Item
                        name={'bidstart_time'}
                        style={{display: 'inline-block'}}
                        rules={[{required: true, message: '请选择投标开始时间'}]}
                    >
                        <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
                    </Form.Item>
                    <span style={{textAlign: 'center', lineHeight: '25px', fontSize: '32px'}}>~</span>
                    <Form.Item style={{display: 'inline-block'}} name={'bidend_time'}
                               rules={[{required: true, message: '请选择投标结束时间'}]}>
                        <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
                    </Form.Item>
                </Form.Item>
                <Form.Item label={'招标文件变更内容'} labelCol={{ span: 3}}
                           wrapperCol={{ span: 21 }} required>
                    <BraftEditor
                        excludeControls={excludeControls}
                        readOnly={readOnly}
                        value={editor}
                        onChange={handleEditorChange}
                        className={css.editor}
                        contentStyle={{height:'200px'}}
                        controlBarStyle={{display: readOnly? 'none' :''}}
                    />
                </Form.Item>

               <Form.Item labelCol={{ span: 3}} wrapperCol={{ offset:3,span: 12 }}>
                   <UploadFile accept={' .PDF, .PPT, .PPTX, .DOCX, .DOC'} uploadNum={3}
                                       handleFileChange={handleFileChange} defaultFileList={params.attachment_url} />
               </Form.Item>
                <div className={css.btns}>
                    <Button size={ "large"} className={css.saveBtn}  onClick={ submit }>
                        保存
                    </Button>
                    <Button type="primary" size={"large"} onClick={ submitVerify } >
                        提交审批
                    </Button>
                </div>

                <Modal
                    title="选择审批人"
                    visible={contactsVisible}
                    onOk={handleContactsOk}
                    onCancel={handleCancel}
                >
                    <Contacts success={contactsSuccess} users={users}/>
                </Modal>
            </Form>
        }
        </div>);
}