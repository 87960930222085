import React, {Fragment, useEffect, useState} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {UploadFile, YcPagination} from '../../../components';
import classNames from 'classnames';
import {Button, Form, Input, message, Modal, Tooltip} from 'antd';
import {useDataApi} from '../../../hook';
import {useHistory} from "react-router-dom";
import css from './index.module.less';
import {ClosePro} from "../../../components/close-pro";
import {MODE_OFF, QA_COMMEND, QA_OPEN} from "../../../common/static_number";
import {PublishModal} from "../../../common";
import {tap} from "rxjs/operators";
import {useToggle} from "react-use";

const {Search} = Input;
export const Project = (props) => {

    const {push} = useHistory();
    const [params, setParams] = useState({
        page: 1,
        type: '1',
        total: 0,
        project_name: props.location.state ? props.location.state.project_sn : ''
    }); //type '1' 招标项目 '2' 采购项目 '3'投标项目 '4' 采购报价 5 开标项目
    const [visible, setVisible] = useState(false); //删除弹窗控制
    const [project, setProject] = useState('');
    const [updateVisible, toggle] = useToggle(false); //修改报价弹窗控制
    const [form] = Form.useForm();
    const [{data: dataSource, isLoading/*, isError */}, updateParams] = useDataApi();
    useEffect(() => {
        const {project_sn = '', type = '1'} = props.location.state || {};

        let _params = params;
        _params.project_name = project_sn;
        _params.type = type;


        setParams(_params);
    }, [props.location.state]);
    useEffect(() => {
        if (!isLoading) {
            const {total} = dataSource;
            const _params = params;
            _params.total = total;
            setParams(_params);
        }
    }, [isLoading]);
    useEffect(() => {
        const {project_name, type, page} = params;
        updateParams({
            url: '/api/user/myProjectList',
            method: 'post',
            data: {page, type, project_name}
        })
    }, [params]);

    // const goResult = (project_sn) => {
    //     push({pathname: '/market/apply/result', state: {project_sn}})
    // };

    const handleTypeChange = (value) => {
        let _params = params;
        _params.type = value;
        setParams({..._params});

    };

    const handleSearch = (value) => {
        let _params = params;
        _params.project_name = value;
        _params.page = 1;
        setParams({..._params});
    };

    const handleMessage = (project_sn) => {
        Modal.warning(
            {
                content: '您还拥有一次选择中标人的机会，如果本次选择的中标人拒绝之后，本项目将会自动流标！',
                okText: '确定',
                onOk: () => {
                    push({pathname: '/publish/listConfirm', state: {project_sn, step: 1}})
                }
            });
    };


    /** 终止项目 */
    const closePro = ({project_sn}) => {

        setVisible(true);
        setProject(project_sn);
    };

    /** 删除项目 */
    const delProject = ({project_sn}) => {
        Modal.confirm({
            title: '消息通知',
            content: '确定要删除本项目嘛？',
            okText: '确定',
            cancelText: '取消',
            maskClosable: true,
            onOk: () => {
                PublishModal.delProject({project_sn}).pipe(
                    tap()
                ).subscribe({
                    next: res => {
                        message.success('删除成功', 3);
                        window.location.reload();
                    },
                    error: err => {
                    },
                    complete: () => {
                    }
                });
            },
            onCancel: () => {

            }
        });
    };
    const menu = (opt) => {
        const {status, project_sn = '', tendering_mode = '',is_set} = opt;

        const {type = '1'} = params;
        switch (type) {
            case '1': //招标项目
                if (status === 0) { //项目创建成功 设置经办人
                    return (<Fragment>
                        <div className={css.btn} onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        {tendering_mode === MODE_OFF ? <div className={css.btn} onClick={() => push({
                            pathname: '/publish/off',
                            state: {project_sn, step: 1}
                        })}>设置经办人</div> : <div className={css.btn} onClick={() => push({
                            pathname: '/publish',
                            state: {project_sn, step: 2}
                        })}>设置经办人</div>
                        }
                        <div className={css.btn} onClick={() => delProject({project_sn})}>删除</div>
                    </Fragment>);
                } else if (status === 24) { //经办人设置成功 编辑草稿
                    return (<Fragment>
                        <div className={css.btn}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        {tendering_mode === MODE_OFF ? <div className={css.btn} onClick={() => push({
                                pathname: '/publish/off',
                                state: {project_sn, step: 2}
                            })}>编辑</div> :
                            <div className={css.btn} onClick={() => push({
                                pathname: '/publish',
                                state: {project_sn, step: 3}
                            })}>编辑</div>
                        }
                        <div className={css.btn} onClick={() => delProject({project_sn})}>删除</div>
                    </Fragment>);
                } else if (status === 1 || status === 2 || status === 3 || status === 31 || status === 4) { //招标公告审批中
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        {tendering_mode === MODE_OFF ? <div className={css.btn} onClick={() => push({
                                pathname: '/publish/off',
                                state: {project_sn, step: 3}
                            })}>查看公告审批</div> :
                            <div className={css.btn} onClick={() => push({
                                pathname: '/publish',
                                state: {project_sn, step: 4}
                            })}>查看公告审批</div>
                        }
                    </Fragment>);
                } else if (status === 30) { //平台审核通过
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish',
                            state: {project_sn, step: 5}
                        })}>查看报名
                        </div>
                    </Fragment>);
                } else if (status === 37) {// 报名已结束
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish',
                            state: {project_sn, step: 5}
                        })}>查看报名
                        </div>
                    </Fragment>);
                } else if (status === 5 || status === 7) { //报名结果通知
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish',
                            state: {project_sn, step: 6}
                        })}>发送资审通知
                        </div>
                    </Fragment>);
                } else if (status === 6) { //报名审批通过
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish',
                            state: {project_sn, step: 6}
                        })}>发送资审通知
                        </div>
                    </Fragment>);
                } else if (status === 20) { //设置开标人
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listSend',
                            state: {project_sn, step: 1}
                        })}>设置开标人
                        </div>
                    </Fragment>);
                } else if (status === 8) { // 编辑招标文件
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listSend',
                            state: {project_sn, step: 2}
                        })}>编辑
                        </div>

                    </Fragment>);
                } else if (status === 9 || status === 10) { //招标文件审批中
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listSend',
                            state: {project_sn, step: 3}
                        })}>查看文件审批
                        </div>
                    </Fragment>);
                } else if (status === 11) { //招标文件审批不通过
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listSend',
                            state: {project_sn, step: 2}
                        })}>编辑
                        </div>
                    </Fragment>);
                } else if (status === 12) {//发布招标文件
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listSend',
                            state: {project_sn, step: 4}
                        })}>查看投标情况
                        </div>
                    </Fragment>);
                } else if (status === 13) { //选择评标人
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listCommend',
                            state: {project_sn, step: 1}
                        })}>设置评标时间
                        </div>
                    </Fragment>;
                } else if (status === 38) { //已开标
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listOpen',
                            state: {project_sn, step: 2}
                        })}>进入开标大厅
                        </div>
                    </Fragment>;
                } else if (status === 14) {//评标中
                    return (<Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listCommend',
                            state: {project_sn, step: 2}
                        })}>查看评标情况
                        </div>
                    </Fragment>);
                } else if (status === 15) { //评标完成
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listCommend',
                            state: {project_sn, step: 3}
                        })}>查看评标结果
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listConfirm',
                            state: {project_sn, step: 1}
                        })}>编写定标报告
                        </div>
                    </Fragment>
                } else if (status === 17 || status === 19) { //定标审批中 定标审批不通过
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listConfirm',
                            state: {project_sn, step: 2}
                        })}>中标审批结果
                        </div>
                    </Fragment>
                } else if (status === 18) { //定标审批通过
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listConfirm',
                            state: {project_sn, step: 2}
                        })}>中标审批结果
                        </div>
                    </Fragment>
                } else if (status === 21 || status === 22 || status === 23) { //中标公示审批状态
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listConfirm',
                            state: {project_sn, step: 3}
                        })}>公示审批结果
                        </div>
                        {/*<Button style={{textAlign:'center'}} onClick={()=>push({pathname:'/publish/listConfirm',state:{project_sn,step:3}})}>编辑中标公示</Button>*/}

                    </Fragment>
                } else if (status === 32 || status === 33 || status === 28) {// 中标公示审批中
                    return <Fragment>

                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        {tendering_mode === MODE_OFF ? '' : <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listConfirm',
                            state: {project_sn, step: 3}
                        })}>查看审批结果</div>
                        }
                    </Fragment>
                } else if (status === 33) {// 中标公示审批通过
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listConfirm',
                            state: {project_sn, step: 3}
                        })}>发布中标公示
                        </div>
                    </Fragment>
                } else if (status === 25) {// 已发送中标通知

                    return <div className={css.btn} style={{textAlign: 'center'}}
                                onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情</div>
                } else if (status === 26) {// 中标人接受投标
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/publish/listConfirm',
                            state: {project_sn, step: 3}
                        })}>编辑中标公示
                        </div>
                    </Fragment>
                } else if (status === 27) {// 中标人拒绝投标
                    return <Fragment>
                        <div className={css.btn} style={{textAlign: 'center'}}
                             onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情
                        </div>
                        <div className={css.btn} onClick={() => {
                            handleMessage(project_sn)
                        }}>重新选择中标人
                        </div>
                    </Fragment>
                } else if (status === 29) {// 已流标
                    return <div className={css.btn} style={{textAlign: 'center'}}
                                onClick={() => push({pathname: '/detail', state: {project_sn}})}>查看详情</div>
                }

                return

            case '2': //采购项目
                if (status === 0) { //项目创建成功 设置经办人
                    return (<Fragment>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/procurement',
                            state: {project_sn, step: 1}
                        })}>设置经办人
                        </div>
                        <div className={css.btn} onClick={() => delProject({project_sn})}>删除</div>
                    </Fragment>);
                } else if (status === 24) { //经办人设置成功 编辑草稿
                    return (<Fragment>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/procurement',
                            state: {project_sn, step: 2}
                        })}>编辑采购信息
                        </div>
                        <div className={css.btn} onClick={() => delProject({project_sn})}>删除</div>
                    </Fragment>);
                } else if (status === 1 || status === 2 || status === 3 || status === 31 || status === 4) { //招标公告审批中
                    return (<Fragment>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/procurement',
                            state: {project_sn, step: 3}
                        })}>查看审批
                        </div>
                        <div className={css.btn} onClick={() => closePro({project_sn})}>终止采购</div>
                    </Fragment>);
                } else if (status === 30) { //平台审核通过
                    return (<Fragment>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/procurement',
                            state: {project_sn, step: 4, type: 1}
                        })}>查看报价
                        </div>
                        <div className={css.btn} onClick={() => closePro({project_sn})}>终止采购</div>
                    </Fragment>);
                } else if (status === 37) {// 采购已结束
                    return (<Fragment>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/procurement',
                            state: {project_sn, step: 4, type: 1}
                        })}>查看报价
                        </div>
                    </Fragment>);
                } else {
                    return (<Fragment>
                        <div className={css.btn} onClick={() => push({
                            pathname: '/procurement',
                            state: {project_sn, step: 4, type: 1}
                        })}>查看报价
                        </div>
                    </Fragment>);
                }
            case '5': //采购
                return (<Fragment>
                    {(status === 8 || status === 9 || status === 10 || status === 11) && !is_set &&
                    <div   className={css.btn} onClick={() => push({
                        pathname: '/operator/Command',
                        state: {project_sn: opt.project_sn}
                    })}>设置开标口令</div>}
                    {status === 12 && <div  className={css.btn}onClick={() => push({
                        pathname: '/publish/listOpen',
                        state: {project_sn: opt.project_sn, step: 1}
                    })}>开标</div>}
                </Fragment>);
            default:
                return

        }
    };

    function projectNameChange(e) {
        params.project_name = e.target.value;
    }

    const goDetail = (opt) => {
        const {type} = params;
        switch (type) {
            case "1": //招标项目
                push({pathname: '/market/view/' + opt.project_sn});
                break;
            case '2': // 采购
                push({pathname: '/bidding/view/' + opt.project_sn});
                break;
            case '3': //投标
                push({pathname: '/market/view/' + opt.project_sn});
                break;
            case '4': // 采购报价
                push({pathname: '/bidding/view/' + opt.project_sn});
                break;
            default:
        }
    };
    const [{data: apply, isLoading: applyLoading}, applyApi] = useDataApi();

    useEffect(() => {
        if (project && updateVisible) {
            applyApi({
                url: '/api/getOffer',
                method: 'post',
                data: {project_sn: project}
            })
        }
    }, [project, updateVisible]);

    useEffect(() => {
        if (!applyLoading) {
            form.setFieldsValue({
                money: apply.money,
                store_user_name: apply.store_user_name,
                store_user_phone: apply.store_user_phone,
                remark: apply.store_remark,
            })
        }

    }, [apply, applyLoading]);

    const onSubmit = data => {
        PublishModal.projectOffer({
            project_sn: project,
            is_change: '1',
            ...data,
        }).pipe(
            tap()
        ).subscribe({
            next: (res) => {
                message.success('您的报价已经提交成功！', 1);
                toggle(false);
            },
            error: err => {
            },
            complete: () => {
            }
        })
    };

    return (
        <div className={css.body}>
            <Card title="项目管理" cardStyle={{padding: '20px 0px'}}>
                <Form.Item wrapperCol={{span: 10}} style={{padding: '0 20px'}}>
                    <Search
                        placeholder="请输入项目名称/项目编号进行搜索"
                        enterButton="搜索"
                        defaultValue={params.project_name}
                        onChange={projectNameChange}
                        onSearch={value => handleSearch(value)}
                    />
                </Form.Item>
                <Form.Item label={'项目类别'} wrapperCol={{span: 10}} style={{padding: '0 20px'}}>
                    <div className={css.typeBox}>
                        <div className={params.type === '1' ? css.active : ''}
                             onClick={() => handleTypeChange('1')}>招标项目
                        </div>
                        <div className={params.type === '5' ? css.active : ''}
                             onClick={() => handleTypeChange('5')}>开标项目
                        </div>
                        <div className={params.type === '2' ? css.active : ''}
                             onClick={() => handleTypeChange('2')}>采购项目
                        </div>
                    </div>
                </Form.Item>
                <div className={classNames(css.header)}>
                    <div className={css.title}>项目详情</div>
                    <div className={css.status}>状态</div>
                    <div className={css.time}>操作时间</div>
                    <div className={css.operation} style={{width: '160px'}}>操作</div>
                </div>
                <div className={css.list}>
                    {!isLoading && dataSource.list.map(opt =>
                        <div className={css.row} key={opt.project_sn}>
                            <div className={css.title}>
                                <div className={css.name} onClick={() => goDetail(opt)}>
                                    {opt.project_name || '未命名'}
                                </div>
                                <div className={css.infoWrap}>
                                    <p>项目编号：{opt.project_sn}</p>
                                    <p>所属行业：{opt.industry_type}-{opt.industry_id}</p>
                                    {params.type !== '2' && params.type !== '4' ?
                                        <Fragment>
                                            <p>招标类型：{opt.tendering_type_text}</p>
                                            <p>招标方式：{opt.tendering_mode === MODE_OFF ? '仅线上发布招标公告' : '在线电子招投标'}</p>
                                            <p>项目类别：{opt.type_text}</p>
                                            <p>采购单位：{opt.store_id_text}</p>
                                        </Fragment> : <Fragment>
                                            <p>项目类别：{opt.type_text}</p>
                                            <p>招标单位：{opt.store_id_text}</p>
                                        </Fragment>}

                                    {
                                        opt.address && <p>所属地区：{opt.address}</p>
                                    }
                                </div>
                            </div>
                            <div className={css.status}>
                                <Tooltip title={opt.status_texts}>
                                    {opt.status_text}
                                </Tooltip>
                            </div>
                            <div className={css.time}>{opt.updatetime}</div>
                            <div className={css.operation} style={{width: '160px'}}>
                                {menu(opt)}
                                {   //未发布
                                    ![0, 1, 2, 3, 4, 24, 31].includes(opt.status) &&
                                    <Fragment>{
                                        params.type !== '2' && params.type !== '4' && opt.tendering_mode !== MODE_OFF &&
                                    <div className={css.btn} onClick={() => push({
                                        pathname: '/publish/commune',
                                        state: {project_sn: opt.project_sn}
                                    })}>答疑列表</div>}
                                        {params.type === '1' && opt.status !== 36 &&
                                        <div className={css.btn}
                                             onClick={() => closePro({project_sn: opt.project_sn})}>终止招标</div>}
                                    </Fragment>

                                }
                            </div>
                        </div>
                    )}

                    <YcPagination
                        total={params.total || 0}
                        pageSize={8}
                        current={params.page}
                        onChange={(e) => {
                            const _params = params;
                            _params.page = e;
                            setParams({..._params})
                        }}
                        style={{margin: '30px 0'}}/>
                </div>
            </Card>
            <ClosePro visible={visible} setVisible={setVisible} project={project}/>
            <Modal
                title="报价通知"
                visible={updateVisible}
                onOk={toggle}
                onCancel={toggle}
                footer={null}
                centered destroyOnClose mask maskClosable={false}>
                <Form onFinish={onSubmit} wrapperCol={{span: 20}} labelCol={{span: 4}} className={css.formWrap}
                      form={form}>
                    <Form.Item label={'报价金额'} name={'money'} rules={[{required: true, message: '请输入报价金额'}]}>
                        <Input style={{width: '100%'}} autoComplete="off" placeholder="请输入报价金额"/>
                    </Form.Item>
                    <Form.Item label={'联系人'} name={'store_user_name'} rules={[{required: true, message: '请输入联系人姓名'}]}>
                        <Input style={{width: '100%'}} autoComplete="off" placeholder="请输入联系人姓名"/>
                    </Form.Item>
                    <Form.Item label={'联系电话'} name={'store_user_phone'} rules={[{required: true, message: '请输入联系电话'}]}>
                        <Input style={{width: '100%'}} autoComplete="off" placeholder="请输入联系电话"/>
                    </Form.Item>

                    <Form.Item label={'备注'} name={'remark'}>
                        <Input.TextArea style={{width: '100%'}} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item
                        name={'file'}
                        wrapperCol={{offset: 4, span: 16}}
                    >
                        <UploadFile accept={' .PDF, .PPT, .PPTX, .DOCX, .DOC'} uploadNum={3} defaultFileList={[]}/>
                    </Form.Item>
                    <div className={css.btnWrap}>
                        <div className={css.btnInner}>
                            <Button type="primary" htmlType="submit">提交</Button>
                            <Button onClick={() => form.resetFields()}>重置</Button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}