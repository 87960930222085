import React from 'react';
import {Steps} from 'antd';

import css from './flow.module.less';
import classNames from "classnames";

const {Step} = Steps;

export const Flow = (props) => {
    const {superStep, step,superList=[],stepList} = props;
    return (
        <div className={css.flow}>
            <div className={css.superList}>
                {superList.map((opt, index) =>
                    <div className={classNames(css.super, superStep === opt.step ? css.check : '')} key={'superList'+index}>
                        <div className="name">
                            {index + 1}.{opt.name}
                        </div>
                        <div className={css.active}/>
                    </div>
                )}
            </div>
            <div className={css.steps}>
                <Steps progressDot initial={1} current={step}>
                    {stepList.map((opt, index) =>
                        <Step title={opt.stepName} description="" key={'items'+index} disabled={ opt.disabled} className={opt.disabled? css.disabled :''} />
                    )}

                </Steps>
            </div>
        </div>
    );
}