import React, {useEffect, useState} from "react";
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import css from "./notice.module.less";
import moment from 'moment';
import {Button, DatePicker, Descriptions, Form, Input, message, Modal} from 'antd';
import {AddressItem, PriceInput, UploadFile, UploadImage} from "../../../../components";
import {PublishModal, ServicesModel} from "../../../../common";
import {tap} from "rxjs/operators";
import {useDataApi} from "../../../../hook/dataApi";
import {AUDIT_PUBLISH} from "../../../../common/static_number";
import locale from 'antd/es/date-picker/locale/zh_CN';
import {useHistory} from "react-router-dom";
import {Contacts} from "../../components";
import {PreviewBidding} from "../../../preview/previewBidding";

const layout = {
    labelCol: {span: 3},
    wrapperCol: {span: 10},
};
const modalCol = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};
const {  RangePicker } = DatePicker;

/** 发布公告 */
export const Notice = (props) => {
    const {params:{project_sn=""}} = props;
    const [form] = Form.useForm();
    const [params, setParams] = useState({file: []});

    const [contactsVisible, showContactsVisible] = useState(false); //审批人弹窗控制
    const [users, setUsers] = useState([]); //选中的审批人
    const [project, setProject] = useState();
    const [previewFlag, showPreview] = useState(false); //预览弹窗控制

    const [count, setCount] = useState(60);
    const [countdown, showCountdown] = useState(false);
    const [initialData, setInitialData] = useState({});
    const [auditFlag, setAuditFlag] = useState(false);

    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    const {push} = useHistory();

    useEffect(() => {
        if (!isLoading) {
            let _data = data;
            const {images,file,user_name,user_phone} = _data;
            const _param = params;
            const signup_time = data.signup_time? moment(data.signup_time) : null;
            const signend_time = data.signend_time? moment(data.signend_time ) :null;
            _data.rangePicker = [signup_time,signend_time];
            _data.count = {number: data.total,unit: _data.unit_name};
            _data.address = _data.province_id?[_data.province_id,_data.city_id,_data.county_id]:void 0;
            _data.images = images || [];
            _data.file = file || [];
            _data.content = BraftEditor.createEditorState(_data.content);
            setParams({..._param,...{ user_name,user_phone}});
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);

    /** 打开预览 */
    const openPreview = ()=>{
        form.validateFields().then((values) => {
            let _params = Object.assign({...values}, {...params});
            _params.code = codeVale;
            _params.content = values.content.toHTML();
            const {category_name,category_trade} = data;
            const [signup_time,signend_time] = _params.rangePicker;
            const {number:total,unit,other} = _params.count;
            const unit_name = unit === '' ? other : unit;
            _params.signend_time =  signend_time.format('YYYY-MM-DD HH:mm');
            _params.signup_time = signup_time.format('YYYY-MM-DD HH:mm') ;
            setProject ({
                unit_name,total,
                category_name,category_trade,
                project_sn, ..._params
            });
            showPreview(true);
        })

    };

    /** 关闭预览弹窗 */
    const closePreview = (value) => {
        showPreview(false);
    };


    /** 文件上传成功事件 */
    const handleFileChange = (val) => {
        let _params = params;
        _params.file = val;
        setParams(_params);
    };

    const excludeControls = ['media', 'emoji'];

    /** 获取手机验证码 */
    const getCode = () => {
        const {user_phone} = initialData;
        startCountdown();
        ServicesModel.getSendCode({mobile: user_phone, event: 'addpurchase'}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:(res)=>{
                message.success('验证码已发送')
            }
        });
    };
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                    setCount(60);
                }
            }
            , 1000);

    };


    /** 提交验证 */
    const submitVerify = () => {
        form.validateFields().then((values) => {
            setAuditFlag(true);
        })
    };

    let Loading = false;
    /** 提交事件 */
    const submit = ({is_approval = 0}) => {
        if (Loading) {
            message.warn('正在提交，不要心急哟~');
            return
        }
        const values =  form.getFieldsValue(['project_name','product_name','starting_price','content','images']);
        const {number:total,unit,other} = form.getFieldValue('count');
        const unit_name = unit === '' ? other : unit;
        let _params = Object.assign({...values}, {total,unit_name,...params});
        _params.code = codeVale;
        _params.content = encodeURI(values.content.toHTML());

        const [province_id,city_id,county_id] = form.getFieldValue('address');
        const [signup_time,signend_time] = form.getFieldValue('rangePicker') || [];

        _params.signend_time = signend_time ? signend_time.format('YYYY-MM-DD HH:mm') : '';
        _params.signup_time = signup_time ? signup_time.format('YYYY-MM-DD HH:mm') : '';

        PublishModal.savePurchase({is_approval,project_sn,province_id,city_id,county_id, ..._params}).then(({data: {msg, code}}) => {
            Loading = false;
            if (code === 200) {
                setAuditFlag(false);
                if(is_approval === 0){
                    message.success('保存成功！',3);
                }else{
                    message.success('提交成功！',3,  push({pathname:'/procurement',state:{step:3,project_sn}}));
                };
            } else {
                message.error(msg)
            }
        })
    };

    const [codeVale, setCodeValue] = useState();
    /** 输入验证码事件 */
    const onCodeChange = e => {
        setCodeValue(e.target.value)
    };
    /** 审批人弹窗选择完成 */
    const handleContactsOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        showContactsVisible(false);
    };
    const handleAuditOk = e => {
        if (!codeVale) {
            message.error('请输入手机验证码');
            return
        }
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        const audit = users[0];
        PublishModal.setResponsible({
            type: AUDIT_PUBLISH, project_sn, is_approval:1, ...audit
        }).then(({data: {data, msg, code}}) => {
            if (code === 200) {
                submit({is_approval: 1});
            } else {
                message.error(msg)
            }
        })

    }
    /** 人员选择回调函数 */
    const contactsSuccess = e => {
        setUsers(e.map(item => {
            return {username: item.title, user_id: item.id}
        }));
    };
    /** 关闭审批人弹窗 */
    const handleCancel = e => {
        showContactsVisible(false);
    };
    /** 关闭提交审批弹窗 */
    const handleAuditCancel = e => {
        setAuditFlag(false);
    };

    function disabledDate(current) {
        // Can not select days before today and today
        console.log("current ",current.format('YYYY-MM-DD HH:mm'));
        return current && current < moment().subtract(1, 'days');
    };

    return (
        <div>
            <div className={css.notice}>
                <div className={css.boxTitle}>
                    编辑采购信息
                    <div className={css.info}>
                        <span >项目编号：</span>
                        <span className={css.high}>{project_sn}</span>
                    </div>
                </div>
                {!initialData.isLoading ||
                <Form
                    {...layout}
                    initialValues={initialData}
                    scrollToFirstError={true}
                    form={form}
                >
                    <Form.Item label="项目名称" name="project_name" wrapperCol={{span: 10}} rules={[{required: true, message: '请填写项目名称'},{min: 6,message: '项目名称最少为6个字符'},{max: 50,message:'项目名称最长为50个字符'}]}
                    >
                        <Input placeholder='请填写项目名称' autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="采购产品" name="product_name" wrapperCol={{span: 10}} rules={[{required: true, message: '请填写采购产品'}]}
                    >
                        <Input placeholder='请填写采购产品名称' autoComplete="off"/>
                    </Form.Item>
                    <Form.Item
                        label="采购区域"
                        name="address"
                        required
                        rules={[({getFieldValue})=>({
                            validator(rule,value){
                                if(!value || value.length < 3){
                                    return Promise.reject('请选择采购区域');
                                }
                                return Promise.resolve();
                            }
                        })]}
                    >
                        <AddressItem size="default"/>
                    </Form.Item>
                    <Form.Item label="采购时间"  name={'rangePicker'}  wrapperCol={{span: 10}} rules={[{required: true, message: '请选择采购时间'}]}>
                        <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" locale={locale} disabledDate={disabledDate} style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item label="采购单价" wrapperCol={{span:3}} name="starting_price" rules={[{required: true,message:'请填写采购单价'}]}>
                        <Input type='number' placeholder='采购单价' suffix={'元'}/>
                    </Form.Item>
                    <Form.Item label="采购总量" name="count" required rules={[()=>({
                        validator(rule,value){
                            if(!value || !value.number ){
                                return Promise.reject('请填写采购总量');
                            }else if(value.unit === '' && !value.other){
                                return Promise.reject('请选择采购单位');
                            }
                            return Promise.resolve();
                        }
                    })]}>
                        <PriceInput/>
                    </Form.Item>
                    <Form.Item
                        labelCol={{span: 3}}
                        label="上传项目图片"
                        name='images'
                        rules={[{required: true, message: '请上传项目图片'}]}
                    >
                        <UploadImage uploadNum={3}/>
                    </Form.Item>

                    <Form.Item
                        label={'采购详情'}
                        labelCol={{span: 3}}
                        wrapperCol={{span: 24}}
                        name={'content'}
                        rules={[{required: true,message:'请填写公告正文'},({getFieldValue})=>({
                            validator(rule,value){
                                if(value && value.toHTML() === '<p></p>'){
                                    return Promise.reject('请填写公告正文');
                                }
                                return Promise.resolve();
                            }
                        })]}
                    >
                        <BraftEditor
                            excludeControls={excludeControls}
                            style={{border: '1px solid #ddd'}}
                            contentStyle={{height: '200px'}}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{offset: 3, span: 16}}
                    >
                        <UploadFile uploadNum={3} accept={' .PDF, .PPT, .PPTX, .DOCX, .DOC'}
                                    handleFileChange={handleFileChange} defaultFileList={params.file}/>
                    </Form.Item>
                    <div className={css.btns}>
                        <Button size={"large"} onClick={openPreview}>预览</Button>

                        <Button size={"large"} className={css.saveBtn} onClick={submit}>
                            保存
                        </Button>
                        <Button type="primary" size={"large"} onClick={submitVerify}
                                disabled={isLoading}>
                            提交审批
                        </Button>
                    </div>
                    <Descriptions style={{marginLeft: '150px'}}>
                        <Descriptions.Item label="" >
                            温馨提示<br/>
                            1、您提交的采购信息，未经网站审核，暂不对外显示。<br/>
                            2、您可前往会员中心查看信息审核状态。<br/>
                            3、发布过程中，如遇问题请来电咨询：400-660-0813。<br/>
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
                }
                <Modal title={'提交审批'}
                       visible={auditFlag}
                       onOk={handleAuditOk}
                       onCancel={handleAuditCancel}
                       {...modalCol}
                >
                    <Form.Item {...modalCol} label={'联系人'} style={{marginBottom: '0'}}> {initialData.user_name}</Form.Item>
                    <Form.Item {...modalCol} label={'联系电话'} style={{marginBottom: '10px'}}> {initialData.user_name && initialData.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Form.Item>
                    <Form.Item {...modalCol} label="手机验证码" style={{marginBottom: '0'}} required>
                        <Form.Item
                            wrapperCol={{span: 23}}
                            style={{display: 'inline-block'}}
                        >
                            <Input placeholder='请输入手机验证码' onChange={onCodeChange} autoComplete="off"/>
                        </Form.Item>
                        {
                            !countdown ?
                                <Form.Item style={{display: 'inline-block'}}>
                                    <Button type="primary" onClick={() => {
                                        getCode();
                                    }}>获取验证码</Button>
                                </Form.Item> :
                                <Form.Item style={{display: 'inline-block'}}>
                                    <Button type={"primary"} disabled>{count}s后获取</Button>
                                </Form.Item>
                        }
                    </Form.Item>
                    <Form.Item {...modalCol} label="审批人" style={{marginBottom: '0'}} required>
                        <Form.Item
                            wrapperCol={{span: 23}}
                            style={{display: 'inline-block'}}
                        >
                            <Input placeholder={'请选择审批人'} value={users.map(item => {
                                return item.username
                            }).join(',')} disabled/>
                        </Form.Item>
                        <Form.Item style={{display: 'inline-block'}}>
                            <Button type="primary" onClick={() => showContactsVisible(true)}>选择审批人</Button>
                        </Form.Item>
                    </Form.Item>
                </Modal>
                <Modal
                    title="选择审批人"
                    visible={contactsVisible}
                    onOk={handleContactsOk}
                    onCancel={handleCancel}
                >
                    <Contacts success={contactsSuccess} users={users}/>
                </Modal>
            </div>
            <Modal visible={previewFlag}
                   width={940}
                   onCancel={closePreview}
                   footer={false}
            >
                <PreviewBidding project = {project} />

            </Modal>
        </div>);
};