import React, {useState} from "react";
import {Button, Form, Input, message, Modal} from "antd";
import css from "./index.module.less";
import {tap} from "rxjs/operators";
import {useHistory} from "react-router-dom";
import {useDataApi} from "../../hook";
import {AUDIT_PUBLISH} from "../../common/static_number";
import {PublishModal} from "../../common";
import {Flow} from "../components";

const {TextArea} = Input;

/** 供应商接受招标邀请 */
export const Accept = (props) => {

    const superStep = 1; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(1);
    const { project_sn=''} = props.location.state;
    const [refuseModal, showRefuseModal] = useState(false);
    const [content, setContent] = useState('');//审批意见

    const [{data}] = useDataApi({url: '/api/invitationInfo', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    const superList = [
        {name: '报名', id: 2, step: 1},
        {name: '投标', id: 2, step: 2},
        {name: '开标、评标', id: 2, step: 3},
        {name: '中标结果', id: 2, step: 4},
    ];
    let stepList =  [
        {
            stepId: 1,
            stepName: '邀请通知',
            disabled: true
        },
    ];

    const {push} = useHistory();
    const submit = ({is_accept=1})=>{
        PublishModal.invitationMsg({project_sign_id: data.id,project_sn,is_accept,reason:content}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                if(is_accept ===1){
                    message.success('操作成功！',5,push({pathname:'/market/apply',state:{project_sn}}));
                }else{
                    message.success('操作成功！',5,push({pathname:'/account/project'}));
                }
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    const handleRefuse = () => {
        if (!content) {
            message.error('请输入拒绝原因');
            return;
        }
        submit({is_accept:2})
    };
    return (
        <div className='container'>
            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.command}>
                <div className={css.boxTitle}>
                    邀请通知
                    <div className={css.info}>
                        <span >项目名称：</span>
                        <span className={css.high}>{data.project_name}</span>
                        <span >项目编号：</span>
                        <span className={css.high}>{data.project_sn}</span>
                    </div>
                </div>
                <Button className={css.lookBtn} onClick={()=>push({pathname:'/market/view/'+project_sn})}>查看招标公告</Button>
                <div className={css.card}>
                   <div className={css.card_title}>
                       您好，{data.store_name}！
                   </div>
                   <div className={css.card_content}>
                       {data.invitation_remark}
                   </div>

               </div>
                <div className={css.btns}>
                    <Button  size={"large"} onClick={ ()=>showRefuseModal(true) } >
                        拒绝
                    </Button>
                    <Button type="primary" size={"large"} onClick={ submit } >
                        接受
                    </Button>
                </div>

            </div>
            <Modal visible={refuseModal} title={'拒绝邀请'} onOk={handleRefuse}
                   onCancel={() => showRefuseModal(false)}>
                <Form.Item label={'拒绝原因'} wrapperCol={20}>
                    <TextArea autoSize onChange={(e) => setContent(e.target.value)}
                              style={{border: '1px solid #eee', padding: '8px', minHeight: '100px'}}/>
                </Form.Item>
            </Modal>
        </div>
    )
};