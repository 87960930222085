import config from "./config";
import {store,aesEncrypt} from '../helps';
import {tap} from "rxjs/operators";
import cookie from 'react-cookies';


export class ServicesModel {

    static getUserInfo(data){
        return config.getUserInfo(data).pipe(
            tap(({data})=>{
                const expires = new Date();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')

                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 12);
                if (data && data.userinfo && data.userinfo.token) {
                    store.set('userinfo',aesEncrypt(data.userinfo),expires);
                    cookie.save('token', data.userinfo.token,{expires,domain,path:'/'});

                };
            }),
        );
    }

    static doLogin(data){
        return config.doLogin(data).pipe(
            tap(({data})=>{
                const expires = new Date();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')

                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 12);
                if (data && data.userinfo && data.userinfo.token) {
                    store.set('userinfo',aesEncrypt(data.userinfo), Date.now() + 1000 * 60 * 60 * 24 * 12);// 设置过期时间
                    cookie.save('token', data.userinfo.token,{expires,domain,path:'/'});
                };
            }),
        );
    }

    static doLogout(data){
        return config.doLogout(data).pipe(
            tap(res=>{
                store.clearAll();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')
                cookie.remove('token', { domain,path: '/' });
            }),
        );
    }

    // doRegister
    static doRegister(data){
        return config.doRegister(data).pipe(
            tap(({data})=>{
                const expires = new Date();
                const domain = document.domain === 'localhost' ? document.domain :  "." + document.domain.split('.').slice(-2).join('.')

                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                if (data && data.userinfo && data.userinfo.token) {
                    store.set('userinfo',aesEncrypt(data.userinfo),Date.now() + 1000 * 60 * 60 * 24 * 12);// 设置过期时间
                    cookie.save('token',data.userinfo.token,{expires,domain,path:'/'});
                };
            }),
        );
    }

    static getSendCode(data) {
        return config.getSendCode(data);
    }
    /** 验证手机号是否存在 */
    static verifyMobile(data) {
        return config.verifyMobile(data);
    }

    static doResetpwd(data){
        return config.doResetpwd(data);
    }
    /** 保存意见 */
    static saveIdea(data){
        return config.saveIdea(data);
    }

    static getSigns(data){
        return config.getSigns(data).pipe(
            tap(({data})=>{
                const expires = new Date();
                const domain = document.domain === 'localhost' ? document.domain :  "." + document.domain.split('.').slice(-2).join('.')
                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 12);

                if (data && data.token) {
                    cookie.save('token',data.token,{expires,domain,path:'/'});

                }
            })
        );
    }

    static getCityData(data){
        return config.getCityData(data);
    }

    /** 更新头像 */
    static updateAvatar(data){
        return config.updateAvatar(data);
    }



    static doChangeMobile(data){
        return config.doChangeMobile(data).pipe(
            tap(res=>{
                store.remove('userinfo');
            })
        );
    }

    static doUpdateProfile(data){
        return config.doUpdateProfile(data).pipe(
            tap(res=>{
                store.remove('userinfo');
            })
        );
    }

    static doRetrievepwd(data){
        return config.doRetrievepwd(data);
    }

    static getNoticeNum(data){
        return config.getNoticeNum(data);
    }

    /** 身份认证 */
    static authForm(data){
        return config.authForm(data);
    }

}
/** 上传文件相关接口  */
export class UploadConfig {
    /** 创建项目 */
    static fetchUploadToken(data){
        return config.fetchUploadToken(data);
    }
}

/** 发布招标信息接口  */
export class PublishModal {

    /** 创建项目 */
    static createPro(data){
        return config.createPro(data);
    }
    /** 删除项目 */
    static delProject(data){
        return config.delProject(data);
    }
    /** 验证项目标题是否重名 */
    static checkTitle(data){
        return config.checkTitle(data);
    }
    /** 设置经办人 */
    static setResponsible(data){
        return config.setResponsible(data);
    }

    /** 保存公告-公开招标 */
    static saveNoticePublish(data){
        return config.saveNoticePublish(data);
    }

    /** 保存公告-邀请招标 */
    static saveNoticeInvitation(data){
        return config.saveNoticeInvitation(data);
    }
    /*start 采购开始 start*/
    /** 保存采购信息 */
    static savePurchase(data){
        return config.savePurchase(data);
    }
    /** 保存采购信息 */
    static projectOffer(data){
        return config.projectOffer(data);
    }
    /*end 采购结束 end*/


    /** 报名 */
    static noticeApply(data){
        return config.noticeApply(data);
    }
    /** 收藏 */
    static collect(data){
        return config.collect(data);
    }
    /** 取消收藏 */
    static cancelCollect(data){
        return config.cancelCollect(data);
    }

    /** 增加下载数量 */
    static downloadAdd(data){
        return config.downloadAdd(data);
    }

    /** 资质审核 */
    static saveQualificationAudit(data){
        return config.saveQualificationAudit(data);
    }

    /** 审批 */
    static saveAudit(data){
        return config.saveAudit(data);

    }
    /** 撤回审批 */
    static repealAudit(data){
        return config.repealAudit(data);
    }
    /** 发布 */
    static publish(data){
        return config.publish(data);

    }

    /** 提交入围名单 */
    static signApproval (data){
        return config.signApproval(data);
    }
    /** 提交入围名单 */
    static sendInform (data){
        return config.sendInform(data);
    }


    /** 保存招标文件 */
    static saveWriteFile(data){
        return config.saveWriteFile(data);
    }

    /** 发布招标文件 */
    static sendFile(data){
        return config.sendFile(data);
    }

    /** 签收招标文件 */
    static signFile(data){
        return config.signFile(data);
    }
   /** 上传缴纳凭证 */
   static payDeposit(data){
       return config.payDeposit(data);
   }
    /** 发送招标文件 */
    static bidSend(data){
        return config.bidSend(data);
    }

    /** 开标 */
    static open(data){
        return config.open(data);
    }
    /** 获取开标人手机号 */
    static getOpenMobile (data){
        return config.getOpenMobile(data);

    }
    /** 设置开标口令 */
    static setSecretkey(data){
        return config.setSecretkey(data);
    }
    /** 重置开标口令 */
    static resetSecretkey(data){
        return config.resetSecretkey(data);
    }

    /** 设置评标时间 */
    static setBidtime(data){
        return config.setBidtime(data);
    }

    /** 验证可否开标 */
    static validateOpen(data){
        return config.validateOpen(data);
    }

    /** 保存分数 */
    static scoring(data){
        return config.scoring(data);
    }
    /** 提交中标人 */
    static scaling(data){
        return config.scaling(data);
    }
    /** 发布中标通知 */
    static sendInformSuccess (data){
        return config.sendInformSuccess(data);
    }

    /** 保存公告-定标公告 */
    static saveNoticeConfirm(data){
        return config.saveNoticeConfirm(data);
    }

    /** 接收中标结果 */
    static choice(data){
        return config.choice(data);
    }
    /** 发布中标公示 */
    static winBid(data){
        return config.winBid(data);
    }

    /** 接受邀请 */
    static invitationMsg(data){
        return config.invitationMsg(data);
    }

    /** 回复 */
    static submitReply(data){
        return config.submitReply(data);
    }
    /** 提交评分 */
    static submitCommend(data){
        return config.submitCommend(data);
    }
    /** 进入定标阶段 */
    static goConfirm(data){
        return config.goConfirm(data);
    }

    /** 更新消息状态  */
    static updateNotice(data){
        return config.updateNotice(data);
    }

    /** 更新系统消息状态  */
    static resetMessage(data){
        return config.resetMessage(data);
    }
}


/** 员工Service */
export class EmployeeModal{
    /** 添加员工 */
    static addEmployee(data){
        return config.addEmployee(data);
    }
    /** 删除员工 */
    static delEmployee(data){
        return config.delEmployee(data);
    }

    /** 改变员工状态 */
    static employeeFlag(data){
        return config.employeeFlag(data);
    }
}

/** 专家接口 */
export class ExpertModal{
    /** 设置抽取规则 */
    static setRules(data){
        return config.setRules(data);
    }
    /** 发送邀请 */
    static sendInvite(data){
        return config.sendInvite(data);
    }
    /** 同意邀请 */
    static accept(data){
        return config.accept(data);
    }
    /** 专家注册 */
    static register(data){
        return config.register(data).then(
            (res)=>{
                const {data,code,msg} = res.data;
                if(code === 200){
                    store.set('token',data.userinfo.token, new Date().getTime() + 1000*60*60*24);// 设置过期时间
                    store.set('userinfo',aesEncrypt(data.userinfo), new Date().getTime() + 1000*60*60*24);// 设置过期时间
                }
                return new Promise(((resolve, reject) => {
                    resolve({data,code,msg})
                }))
            }
        );
    }
}
