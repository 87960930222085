import React, {useEffect, useState,Fragment} from 'react';
import css from './adopt.module.less';
import {Button, Form, Input, message, Modal,Table} from "antd";
import {Flow} from "../../components";
import {useHistory} from 'react-router-dom';
import {useDataApi} from "../../../hook";
import {PublishModal} from "../../../common";
import {tap} from "rxjs/operators";

const {TextArea} = Input;
export const Adopt = (props) => {
    const superStep = 4;
    const [ step,setStep ] = useState(1);
    const { project_sn=''} = props.location.state;
    const [refuseModal, showRefuseModal] = useState(false);
    const [content, setContent] = useState('');//审批意见
    const [initialData, setInitialData] = useState({});
    const [columns,setColumns] = useState({});//tenderResult

    const [{data}] = useDataApi({url:'/api/prosign',data:{project_sn},method:'post'});
    const [{data: supplierData}] = useDataApi({
        url: '/api/tenderlist',
        method: 'post',
        data: {project_sn},
        initialData: {arr:[]}
    });// 获取公告中已选的供应商
    const superList = [
        {name: '报名', id: 2, step: 1},
        {name: '投标', id: 2, step: 2},
        {name: '开标、评标', id: 2, step: 3},
        {name: '中标结果', id: 2, step: 4},
    ];
    let stepList = [
        {
            stepId: 1,
            stepName: '投标结果',
            disabled: true
        },
    ];


    const {push} = useHistory();
    const submit = ({is_choice=1})=>{
        PublishModal.choice({project_sn,is_choice,reason:content}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('操作成功！',5,push({pathname:'/account/project'}));
            },
            error:err=>{},
            complete:()=>{}
        });
        push({pathname:'/account/project'})
    };

    const handleRefuse = () => {
        if (!content) {
            message.error('请输入拒绝原因');
            return;
        }
        submit({is_choice:2})
    };
    useEffect(()=>{
        if(supplierData.arr.length){
            const _columns = [
                {
                    title: '评标排名',
                    align: 'center',
                    render: (text,row,index)=>{
                        return <span>{index + 1 }</span>
                    },
                    width: 100,
                },
                {
                    title: '供应商名称',
                    dataIndex: 'store_name',
                    key: 'store_name',
                    align: 'center',
                    width: 300,
                },
                {
                    title: '平均得分',
                    dataIndex: 'fraction',
                    key: 'fraction',
                    align: 'center',
                    width: 100,
                },
            ];
            for(let  a = 0 ;a < supplierData.pcount;a++){
                _columns.push({
                    title: supplierData.appuser[a].username,
                    key: supplierData.appuser[a].username,
                    dataIndex: supplierData.appuser[a].username,
                    align: 'center',
                    width: 100,
                    render: (text,row,index)=>{
                        if(text.length){
                         const list =  text.map((item,index)=>{
                                if(item.project_sign_id === row.id){
                                    return <Fragment>
                                        <span>商务：{item.business_fraction}</span> <br/>
                                        <span>技术：{item.technology_fraction}</span>
                                    </Fragment>
                                }
                            });
                            return list
                        }
                    },
                })
            }
            data.isLoading = true;
            setInitialData(supplierData);
            setColumns({isLoading:true,list:[..._columns]});
        }
    },[supplierData]);

    return (
        <div className="container" >

            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.apply}>
                <div className={css.boxTitle}>
                    投标结果
                </div>
                {!columns.isLoading ||  <Table columns={columns.list} pagination={false} dataSource={initialData.arr} bordered scroll={{ x: 1000, y: 500 }} />}
                <div className={{'1':css.resultSuccess,'0':css.resultReject}[data.successful_bid]}>
                    {/*<img alt='' src={{'1':applySuccess,'0':applyReject}[data.successful_bid]} />*/}
                    <div className={css.resultText}>{{'5':'中标通知还没有发布呦~，请耐心等待！','1':'恭喜您，本次项目您中标了！','0':'对不起，本次项目您未中标！'}[data.successful_bid]}</div>
                </div>
                <div className={css.btns}>
                    <Button type="primary" size={"large"} style={{marginLeft: '20px'}} onClick={()=> push({pathname:'/account/project'})}>
                        返回
                    </Button>
                </div>
            </div>
            <Modal visible={refuseModal} title={'拒绝中标项目'} onOk={handleRefuse}
                   onCancel={() => showRefuseModal(false)}>
                <Form.Item label={'拒绝原因'} wrapperCol={20}>
                    <TextArea autoSize onChange={(e) => setContent(e.target.value)}
                              style={{border: '1px solid #eee', padding: '8px', minHeight: '100px'}}/>
                </Form.Item>
            </Modal>
        </div>
    );
}