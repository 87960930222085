import React, {useEffect, useState} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {YcPagination} from '../../../components';
import classNames from 'classnames';
import {Button, Dropdown, Form, Input, Menu, message} from 'antd';
import {useDataApi} from '../../../hook';
import css from './collect.module.less';
import {useHistory} from "react-router-dom";
import {PublishModal} from "../../../common";
import {tap} from "rxjs/operators";

const {Search} = Input;
export const Collect = () => {
    const [params, setParams] = useState({page: 1, type: '1', total: 0});
    const {push} = useHistory();

    const [{data: dataSource, setData, isLoading/*, isError */}, updateParams] = useDataApi({initialData: []});

    useEffect(() => {
        const {project_name: search_name, type, page} = params;
        updateParams({
            url: '/api/collectList',
            method: 'post',
            data: {page, type, search_name}
        })
    }, [params]);

    const handleTypeChange = (value) => {
        let _params = params;
        _params.type = value;
        setParams({..._params});

    };

    const handleSearch = (value) => {
        let _params = params;
        _params.project_name = value;
        _params.page = 1;
        setParams({..._params});
    };
    /** 取消收藏 */
    const handleCancelCollect = (data, index) => {
        console.log("============", data);
        PublishModal.cancelCollect({id: data.cid, type: data.type}).pipe(
            tap(res => console.log(res))
        ).subscribe({
            next: res => {
                message.success('取消成功', 3);
                let _data = dataSource;
                _data.list.splice(index, 1);
                setData({..._data});
            },
            error: err => {
            },
            complete: () => {
            }
        });
    };
    const menu = (opt) => {
        return (<Menu>
            <Menu.Item style={{textAlign: 'center'}} onClick={() => handleCancelCollect(opt)}>取消收藏</Menu.Item>
        </Menu>);
    };
    const goDetail = (opt) => {
        const {type} = params;
        console.log("opt=====", opt);
        switch (type) {
            case "1":
                push({pathname: '/market/view/' + opt.project_sn});
                break;
            case "2":
                push({pathname: '/bidding/view/' + opt.project_sn});
                break;
            case "3":
                push({pathname: '/market/view/' + opt.project_sn});
                break;
            case "4":
                push({pathname: '/baike/view/250/' + opt.cid});
                break;
            case "5":
                push({pathname: '/download/view/251/' + opt.cid});
                break;
            default:
        }

    };
    return (

        <div className={css.body}>
            <Card title="收藏夹">
                <div className={css.searchBox}>
                    <div className={css.typeBox}>
                        <div className={params.type === '1'? css.active : ''} onClick={()=>handleTypeChange('1')}>招标项目</div>
                        <div className={params.type === '2'? css.active : ''} onClick={()=>handleTypeChange('2')}>采购项目</div>
                        <div className={params.type === '4'? css.active : ''} onClick={()=>handleTypeChange('4')}>招标百科</div>
                        <div className={params.type === '5'? css.active : ''} onClick={()=>handleTypeChange('5')}>标书范本</div>
                    </div>
                    <Search
                        style={{width:'283px'}}
                        placeholder="请输入项目关键字进行搜索"
                        enterButton="搜索"
                        onSearch={value => handleSearch(value)}
                    />
                </div>
                <div className={classNames(css.top, css.header)}>
                    <div className={css.name}>项目名称</div>
                    {/*<div className={css.time}>项目进度</div>*/}
                    <div className={css.status}>操作时间</div>
                    <div className={css.operation}>操作</div>
                </div>
                <div className={css.list}>


                    {!isLoading && dataSource.list.map((opt, index) =>
                        <div className={css.row}>
                            <div className={classNames(css.top)}>
                                <div className={css.name} onClick={() => goDetail(opt)}>
                                    {opt.project_name || opt.title || '未命名'}
                                </div>
                                {/*<div className={css.time}>{opt.status_text}</div>*/}
                                <div className={css.status}>{opt.createtime}</div>
                                <div className={css.operation}>
                                    <div className={css.btn} onClick={() => handleCancelCollect(opt, index)}>取消收藏</div>
                                </div>
                            </div>
                        </div>
                    )}
                    <YcPagination
                        total={params.total || 0}
                        pageSize={15}
                        current={params.page}
                        onChange={(e) => {
                            const _params = params;
                            _params.page = e;
                            setParams({..._params})
                        }}
                        style={{margin: '30px 0'}}/>
                </div>
            </Card>
        </div>
    )
}