import React, {Fragment, useEffect, useState} from 'react';
import {Card} from '../components';
import {YcPagination} from '../../../components';
import classNames from 'classnames';
import {Button, Col, Form, Input, Menu, message, Modal, Row, Tooltip} from 'antd';
import {useDataApi} from '../../../hook';
import css from './employee.module.less';
import {EmployeeModal} from "../../../common";
import {tap} from "rxjs/operators";
import {useHistory} from "react-router-dom";
import {PlusSquareOutlined} from '@ant-design/icons'

const {Search} = Input;
export const Employee = (props) => {
    const {push} = useHistory();
    const [params, setParams] = useState({
        page: 1,
        type: '0',
        total: 0,
        status: '2',
        project_name: props.location.state ? props.location.state.project_sn : ''
    });//status:  1=审批 0=未审批 2全部 type: 1 招标 2采购

    const [{data: dataSource, setData, isLoading,}, updateParams] = useDataApi({initialData: []});
    const [modal, showModal] = useState(false);
    const [employeeForm] = Form.useForm();

    useEffect(() => {
        const {name} = params;
        updateParams({
            url: '/api/getstaff',
            method: 'post',
            data: {search_name: name}
        })
    }, [params]);

    const handleSearch = (value, type) => {
        let _params = params;
        _params[type] = value;
        _params.page = 1;
        setParams({..._params});
    };

    const menu = (opt, index) => {
        const {user_id, status} = opt;

        return <Menu>
            {status === 1 && <Menu.Item style={{textAlign: 'center'}} type={"primary"}
                                        onClick={() => statusChange(user_id, index)}>停用</Menu.Item>}
            {status === 2 && <Menu.Item style={{textAlign: 'center'}} type={"primary"}
                                        onClick={() => statusChange(user_id, index)}>启用</Menu.Item>}
            {/*<Menu.Item style={{textAlign:'center'}} type={"primary"} >设为管理员</Menu.Item>*/}
            <Menu.Item style={{textAlign: 'center'}} type={"primary"}
                       onClick={() => delEmployee(user_id)}>删除员工</Menu.Item>
        </Menu>
    };

    function projectNameChange(e) {
        params.name = e.target.value;
    }

    /** 添加员工 */
    const addEmployee = () => {
        employeeForm.validateFields().then((values) => {
            console.log("values", values);
            EmployeeModal.addEmployee({...values}).pipe(
                tap(res => console.log(res))
            ).subscribe({
                next: res => {
                    message.success('添加成功！', 5);
                    showModal(false);
                    window.location.reload();

                },
                error: err => {
                },
                complete: () => {
                }
            });
        })
    };
    const statusChange = (user_id, index) => {
        const _data = dataSource;
        const status = _data[index].status === 1 ? 2 : 1;
        EmployeeModal.employeeFlag({user_id, status}).pipe(
            tap(res => console.log(res))
        ).subscribe({
            next: res => {
                message.success('操作成功！', 5);
                _data[index].status = status;
                setData([..._data]);
            },
            error: err => {
            },
            complete: () => {
            }
        });
    };
    /** 删除员工 */
    const delEmployee = (user_id) => {

        EmployeeModal.delEmployee({user_id}).pipe(
            tap(res => console.log(res))
        ).subscribe({
            next: res => {
                message.success('删除成功！', 5);
                window.location.reload();
            },
            error: err => {
            },
            complete: () => {
            }
        });
    };
    return (
        <div className={css.body}>
            <Card title="员工管理" extra={<div className={css.pubBtn} onClick={() => showModal(true)}>  <PlusSquareOutlined /><span>&nbsp;添加员工</span></div>}>
                <Row>
                    <Col span={12}>
                        <Form.Item wrapperCol={{span: 24}}>
                            <Search
                                placeholder="按照姓名进行搜索"
                                enterButton="搜索"
                                onChange={projectNameChange}
                                onSearch={value => handleSearch(value, 'name')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className={css.list}>
                    <div className={classNames(css.top, css.header)}>
                        <div className={css.name}>部门</div>
                        <div className={css.time}>姓名</div>
                        {/*<div className={css.status} >职位</div>*/}
                        <div className={css.time}>身份</div>
                        <div className={css.status}>状态</div>
                        <div className={css.operation}>操作</div>
                    </div>

                    {!isLoading && dataSource.map((opt, index) =>
                        <div className={css.row} key={opt.id}>
                            <div className={classNames(css.top)}>
                                <div className={css.name}>{opt.depertment_name || '未命名'}</div>
                                <div className={css.time}>{opt.username}</div>
                                <div className={css.time}>{
                                    function () {
                                        switch (opt.is_managemen) {
                                            case 0:
                                                return '员工';
                                            case 1:
                                                return '管理员';
                                            case 2:
                                                return '超级管理员';
                                            default:
                                        }
                                    }()
                                }</div>
                                {opt.is_managemen !== 2 && <Fragment>
                                    <div className={css.status}>{opt.status === 1 ? '正常' : '停用'}</div>
                                    <div className={css.operation}>
                                        {opt.status === 1 && <div className={css.btn} onClick={() => statusChange(opt.user_id, index)}>停用</div>}
                                        {opt.status === 2 && <div className={css.btn} onClick={() => statusChange(opt.user_id, index)}>启用</div>}
                                        {/*<Menu.Item style={{textAlign:'center'}} type={"primary"} >设为管理员</Menu.Item>*/}
                                        <div className={css.delBtn} onClick={() => delEmployee(opt.user_id)}>删除</div>
                                    </div>
                                </Fragment>
                                }

                            </div>
                        </div>
                    )}
                    <YcPagination
                        total={params.total || 0}
                        current={params.page}
                        pageSize={8}
                        onChange={(e) => {
                            const _params = params;
                            _params.page = e;
                            setParams({..._params})
                        }}
                        style={{margin: '30px 0'}}/>
                </div>
            </Card>
            <Modal title="添加员工"
                   visible={modal}
                   centered
                   destroyOnClose
                   onOk={addEmployee}
                   okText={'提交'}
                   onCancel={() => showModal(false)}
            >
                <Form form={employeeForm}
                      labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                >
                    {/*         <Form.Item label='所属部门' name={'content'}>
                        <Input type="text"/>
                    </Form.Item>*/}
                    <Form.Item label='员工姓名' name={'username'} rules={[{required: true, message: '请填写员工姓名'}]}>
                        <Input type="text" autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label='职务' name={'position'}>
                        <Input type="text" autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label='手机号' name={'mobile'} rules={[{required: true, message: '请填写手机号'}]}>
                        <Input type="text" autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label='邮箱' name={'email'} rules={[{required: true, message: '请填写邮箱'}]}>
                        <Input type="text" autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label='登录账号' name={'nickname'} rules={[{required: true, message: '请填写登录账号'}]}>
                        <Input type="text" autoComplete="off"/>
                    </Form.Item>
                    <Tooltip title={<span>长度为6~20个字符,<br/>必须包含字母/数字,<br/>不允许有空格、中文</span>} trigger={['focus', 'hover']}>
                        <Form.Item label='登录密码' name={'password'} rules={[{required: true, message: '请输入密码'}]}>
                            <Input type="text" autoComplete="off"/>
                        </Form.Item>
                    </Tooltip>
                    <Form.Item label='确认密码' name={'repassword'} rules={[{required: true, message: '请确认密码'}]}>
                        <Input type="text" autoComplete="off"/>
                    </Form.Item>
                    {/*         <Form.Item label='职务' name={'content'}>
                        <Input type="text"/>
                    </Form.Item> */}
                </Form>
            </Modal>
        </div>
    )
}