import React, {useEffect, useState} from "react";
import css from "./audit.module.less";
import {Button, Descriptions, Form, message, Modal} from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import {TagAudit} from "../../../../components";
import {useDataApi} from "../../../../hook";
import {AUDIT_SEND, AWAIT, FAIL, resourcesUrl, SUCCESS} from "../../../../common/static_number";
import {PublishModal, ServicesModel} from "../../../../common";
import {tap} from "rxjs/operators";
import {useHistory} from "react-router-dom";

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10},
};

export const Audit = (props) => {
    const { params:{project_sn=''}={}  } = props;
    const readOnly = true;
    let { push } = useHistory();

    const [ form ] = Form.useForm();
    const [initialData, setInitialData] = useState({});
    const [{data, isLoading}] = useDataApi({url: '/api/issuedDetail', method: 'post', data: {type:AUDIT_SEND,project_sn}});
    const [params,setParams] = useState({});
    useEffect(() => {
        if (!isLoading) {
            const _data = data.project_issued;
            _data.bstarttime = data.bstarttime;
            _data.bendtime = data.bendtime;
            _data.starting_price =  data.starting_price;

            const _param = params;
            _param.attachment_url = _data.attachment_url || [];
            setParams(_param);
            setEditor(BraftEditor.createEditorState(_data.content));
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);

    const submit = ()=>{
        PublishModal.sendFile({project_sn}).pipe(
            tap((res)=>{

            })
        ).subscribe({
            next:({data,code})=>{
                console.log('code',code);
                if(code === 2 ){
                    Modal.confirm({
                        title:'系统消息',
                        content:  '开标人还没有设置开标口令哟~',
                        okText:'我知道了',
                        cancelText:'催促一下',
                        maskClosable: true,
                        onOk: ()=>{

                        },
                        onCancel:()=>{
                            ServicesModel.getSendCode({mobile:data,event:'keyword',project_sn}).pipe(
                                tap(res=>console.log(res))
                            ).subscribe({
                                next(res){
                                    message.success('已经帮您催促开标人了哟！',3);
                                }
                            });
                        }
                    });
                }else{
                    message.success('发布成功！',3,push({pathname: '/publish/listSend',state:{project_sn,step:4}}));
                }
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    const [editor,setEditor] = useState();
    const excludeControls = ['media', 'emoji'];
    const handleEditorChange = (editor) => {
        setEditor(editor);
    };
    /** 撤回审批  */
    const handleRepeal = ()=>{
        if(data.status === 9){
            Modal.confirm({
                title:'系统提示',
                content:'您确定要撤回招标文件的审批吗？',
                okText:'确定',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    repeal();
                },
                onCancel:()=>{

                }
            });
        }else{
            repeal();
        }
    };
    const repeal = () => {
        PublishModal.repealAudit({operation_type:'withdraw',project_sn,type:3}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                push({pathname:'/publish/listSend',state:{step:2,project_sn}});
            },
            error:err=>{},
            complete:()=>{}
        });
    }
    return (
        <div className={css.audit}>
            <div className={css.boxTitle}>
                招标文件 {!isLoading &&  <TagAudit data={{'9':{title:'审批中',index:AWAIT},'10':{title:'审批通过',index: SUCCESS},'11':{title:'审批未通过',index: FAIL}}[data.status]}/>}
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                    {
                        (data.status === 11 || data.status === 9) &&  <Button className={'withdrawBtn'} size={"small"} onClick={handleRepeal}>{data.status === 9 ? '撤回' : '编辑'}</Button>
                    }
                </div>
            </div>
            {data.status === 11 &&
            <Descriptions title="审批意见" bordered={true} style={{margin: '20px 0'}}>
                <Descriptions.Item>
                    {data.approvalcontent}
                </Descriptions.Item>
            </Descriptions>
            }
            {!initialData.isLoading ||  <Form
                {...layout}
                initialValues={initialData}
                scrollToFirstError = { true}
                form={form}
            >
                <Form.Item
                    labelCol={{span: 3}}
                    wrapperCol={{span: 10}}
                    label="招标文件标题"
                    name="issued_name"
                >
                    <span>{initialData.issued_name}</span>
                </Form.Item>
                <Form.Item label="项目金额" wrapperCol={{span: 3}}>
                    {initialData.starting_price} (万元)
                </Form.Item>
                <Form.Item
                    labelCol={{span: 3}}
                    wrapperCol={{span: 10}}
                    label="投标保证金"
                    name="issued_name"
                >
                    {data.bond} (万元)
                </Form.Item>
                <Form.Item label="投标时间"  wrapperCol={{span: 12}}>
                    <span>{initialData.bidstart_time} ~ {initialData.bidend_time}</span>
                </Form.Item>
                {initialData.bendtime !== 0 && <Form.Item label="保证金缴纳时间"  wrapperCol={{span: 12}}>
                    <span>{initialData.bstarttime} ~ {initialData.bendtime}</span>
                </Form.Item>}
                <Form.Item label="评标时间"  wrapperCol={{span: 12}}>
                    <span>{initialData.evaluationstart_time} ~ {initialData.evaluationend_time}</span>
                </Form.Item>
                <Form.Item label="评标地点"  wrapperCol={{span: 12}}>
                    {initialData.issed_address} {initialData.address}
                </Form.Item>
                <Form.Item label={'招标文件正文'} labelCol={{ span: 3}}
                           wrapperCol={{ span: 21 }}>
                    <BraftEditor
                        excludeControls={excludeControls}
                        readOnly={readOnly}
                        value={editor}
                        onChange={handleEditorChange}
                        className={css.editor}
                        contentStyle={{height:'200px'}}
                        controlBarStyle={{display: readOnly? 'none' :''}}
                    />
                </Form.Item>
                {
                    params.attachment_url.length > 0 && <Form.Item label='附件' >
                        {params.attachment_url.map((item)=>
                            <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                        )}
                    </Form.Item>
                }
                <div className={css.btns}>
                    <Button type="primary" size={"large"} onClick={ submit } disabled={data.status !== 10} >
                        发布招标文件
                    </Button>
                </div>
            </Form>}
        </div>);
};