import React, {useEffect, useState,Fragment} from "react";
import css from "./tenderDetail.module.less";
import {Button, Descriptions, Form, Input, InputNumber, message} from 'antd';
import {useHistory} from "react-router-dom";
import {useDataApi} from "../../hook";
import {PublishModal} from "../../common";
import {tap} from "rxjs/operators";
import {useEffectOnce} from "react-use";
import {resourcesUrl} from "../../common/static_number";

const {TextArea} = Input;

export const TenderDetail = (props) => {
    const {state:{project_sn='',id,type='',user_id} } = props.location;
    let history= useHistory();
    const [{data, isLoading}] = useDataApi({url: '/api/fileDetail', method: 'post', data: {project_sn,project_sign_id:id,user_id}});
    const [form] = Form.useForm();
    const [params, setParams] = useState({images: [],fit_file: [],business_file:[],technology_file:[]});
    const [initialData, setInitialData] = useState({});

    useEffect(() => {
        if (!isLoading) {
            const _data = data;
            const _param = params;

            _param.offer_file= _data.offer_file ||[];
            _param.qualification_file= _data.qualification_file ||[];
            _param.fit_file= _data.fit_file ||[];
            _param.technology_file= _data.technology_file ||[];
            _param.business_file= _data.business_file ||[];
            setParams(_param);
            _data.isLoading = true;
            setInitialData(_data);
        }

    }, [isLoading]);
    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            };
        };
        scrollToTop();
    });
    const submit = ()=>{
        form.validateFields().then((values) => {
            PublishModal.scoring({project_sign_id:id,project_sn,...values}).pipe(
                tap(res=>console.log(res))
            ).subscribe({
                next:res=>{
                    message.success('保存成功！',1,goBack);
                },
                error:err=>{},
                complete:()=>{}
            });
        })
    };
    const goBack = ()=>{
        history.goBack();
    }
    return (
        <div className='container'>
            {!initialData.isLoading || <div className={css.listOpen}>
                <div className={css.detail}>
                    <div className={css.boxTitle}>
                        标书详情
                        <div className={css.info}>
                            <span >项目编号：</span>
                            <span className={css.high}>{initialData.project_sn}</span>
                            <span >项目名称：</span>
                            <span className={css.high}>{initialData.project_name}</span>
                        </div>
                    </div>
                    <Descriptions >
                        <Descriptions.Item label="供应商" >{initialData.store_name}</Descriptions.Item>
                        <Descriptions.Item label="" style={{textAlign: 'right'}}>
                            <Button type={"primary"} onClick={()=> history.push({
                                pathname: '/publish/commune',
                                state: {project_sn: project_sn, status: initialData.status}
                            })}>澄清</Button>
                        </Descriptions.Item>
                    </Descriptions>

                    {initialData.isLoading  &&
                    <Form  initialValues={initialData}
                           scrollToFirstError={true}
                           form={form}>
                        {params.offer_file && <Form.Item label='报价文件' >
                            {params.offer_file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                        }
                        {params.qualification_file && <Form.Item label='资质文件' >
                            {params.qualification_file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                        }
                        {params.business_file && <Form.Item label='商务标书' >
                            {params.business_file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                        }
                        {params.technology_file && <Form.Item label='技术标书' >
                            {params.technology_file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                        }
                        {params.fit_file&& <Form.Item label='综合标书' >
                            {params.fit_file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                        }
                        {type && <Fragment>
                            <Form.Item
                                label="商务评分"
                                required
                            >
                                <Form.Item style={{display:"inline-block"}}  name={'business_fraction'} rules={[{required: true, message: '请填写商务评分',},{type: 'number', min: 0, max: 100,message:'分数范围为0~100'}]}>
                                    {  type === 'marker' ?<InputNumber  placeholder='请填写商务评分'/> : <span>{initialData.business_fraction}</span>}
                                </Form.Item>
                                <Form.Item style={{display:"inline-block",color: '#999',marginLeft: '20px'}}>
                                    (分数范围为0~100，满分为100分）
                                </Form.Item>
                            </Form.Item>
                            <Form.Item
                                label="技术评分"
                                required
                            >
                                <Form.Item style={{display:"inline-block"}}  name={'technology_fraction'} rules={[{required: true, message: '请填写技术评分',},{type: 'number', min: 0, max: 100,message:'分数范围为0~100'}]}>
                                    {  type === 'marker' ?<InputNumber  placeholder='请填写技术评分'/> : <span>{initialData.technology_fraction}</span>}
                                </Form.Item>
                                <Form.Item style={{display:"inline-block",color: '#999',marginLeft: '20px'}}>
                                    (分数范围为0~100，满分为100分）
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="评标报告" name={'remark'} rules={[{required: true, message: '请填写评标报告'},{type:'string',max:1000,message:'限制字数1000字以内'}]}>
                                {  type === 'marker' ? <TextArea placeholder='请填写评标报告，限制字数1000字以内' style={{minHeight: '100px'}}/> : <span>{initialData.remark}</span>}
                            </Form.Item>
                        </Fragment>}

                    </Form>
                    }

                    <div className={css.btns}>
                        {type === 'marker'?  <Button type="primary" size={"large"} onClick={ submit } >
                            保存
                        </Button>: <Button type="primary" size={"large"} onClick={ goBack } >
                            返回
                        </Button>}

                    </div>
                </div>
            </div> }

        </div>
       );
}