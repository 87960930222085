import React, {Fragment, useEffect, useState} from "react";
import {Button, Form, Input, message, Tooltip} from "antd";
import css from "./setCommand.module.less";
import {Flow} from "../../components";
import {PublishModal} from "../../../common";
import {tap} from "rxjs/operators";
import {useHistory} from "react-router-dom";
import {useDataApi} from "../../../hook";
import {AUDIT_PUBLISH} from "../../../common/static_number";


const layout = {
    labelCol: { span: 3,offset: 5 },
    wrapperCol: { span: 10},
};

/** 设置开标口令 */
export const Command = (props) => {

    const superStep = 3; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(2);
    const [params,setParams] = useState({project_sn:props.location.state.project_sn||''});
     const [{data}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn:params.project_sn}});

    const [form] = Form.useForm();
    useEffect(()=>{
        const {project_sn=''} = props.location.state||{};
        setParams({project_sn});
    }, [props.location.state]);
    const superList = [
        {name: '约标', id: 2, step: 1},
        {name: '发标', id: 2, step: 2},
        {name: '开标', id: 2, step: 3},
        {name: '评标', id: 2, step: 4},
        {name: '定标', id: 2, step: 5},
    ];
    let stepList =  [
        {
            stepId: 1,
            stepName: '设置开标口令',
            disabled: true
        }

    ];
    const history = useHistory();
    const submit = ()=>{
        form.validateFields().then((values) => {
            if(values.secretkey !== values.resecretkey){
                message.error('两次输入的口令不一致');
                return
            }
            PublishModal.setSecretkey({project_sn:params.project_sn,...values}).pipe(
                tap(res=>console.log(res))
            ).subscribe({
                next:res=>{
                    message.success('设置成功！',5, history.goBack());
                },
                error:err=>{},
                complete:()=>{}
            });
        })
    }
    return (
        <div className='container'>
            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.command}>
                <div className={css.boxTitle}>
                    开标大厅
                    <div className={css.info}>
                        <span >项目名称：</span>
                        <span className={css.high}>{data.project_name}</span>
                        <span >项目编号：</span>
                        <span className={css.high}>{data.project_sn}</span>
                    </div>
                </div>
                <Form
                    {...layout}
                    scrollToFirstError = { true }
                    form={form}
                >
                    <Tooltip title={<span>长度为6~10个字符,<br/>必须包含字母/数字,<br/>不允许有空格、中文</span>}  trigger={['focus','hover']} >
                        <Form.Item
                            label={'开标口令'}
                            name="secretkey"
                            rules={[{ required: true, message: '请输入开标口令' },{min: 6,message: '口令最少为6个字符'},{max: 20,message:'口令最长为10个字符'}]}
                        >
                            <Input.Password size="large" style={{width:'100%'}}  placeholder="请输入开标口令"/>
                        </Form.Item>
                    </Tooltip>
                    <Form.Item
                        label={'确认开标口令'}
                        name="resecretkey"
                        rules={[{ required: true, message: '请重新输入开标口令' },{min: 6,message: '口令最少为6个字符'},{max: 20,message:'口令最长为10个字符'}]}
                    >
                        <Input.Password size="large" style={{width:'100%'}}  placeholder="请重新输入开标口令"/>
                    </Form.Item>
                    <div className={css.btns}>
                        <Button type="primary" size={"large"} onClick={ submit } >
                            保存
                        </Button>
                    </div>
                </Form>

            </div>
        </div>
    )
};