import React, {useEffect, useState} from "react";
import css from "./edit.module.less";
import {Button, DatePicker, Form, Input, message, Modal} from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import {AddressItem, UploadFile} from "../../../../components";
import {AUDIT_SEND} from "../../../../common/static_number";
import {PublishModal} from "../../../../common";
import {useDataApi} from "../../../../hook";
import moment from "moment";
import {tap} from "rxjs/operators";
import {Contacts} from "../contacts";
import {useHistory} from "react-router-dom";
import locale from "antd/es/date-picker/locale/zh_CN";
import {QuestionCircleOutlined} from '@ant-design/icons'

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10},
};
const {  RangePicker } = DatePicker;

export const Edit = (props) => {
    const {params:{project_sn=''}={} } = props;
    const readOnly = false;
     const [ form ] = Form.useForm();
    const [params,setParams] = useState({});
    let { push } = useHistory();
    const [users, setUsers] = useState([]); //选中的审批人
    const [contactsVisible, showContactsVisible] = useState(false); //审批人弹窗控制
    const [initialData, setInitialData] = useState({});

    const [{data, isLoading}] = useDataApi({url: '/api/issuedDetail', method: 'post', data: {type:AUDIT_SEND,project_sn}});
    useEffect(() => {
        if (!isLoading) {
            const _data = data.project_issued ||{};
            const _param = params;
            const bidstart_time = _data.bidstart_time?moment(_data.bidstart_time):null;
            const bidend_time =  _data.bidstart_time?moment(_data.bidend_time):null;
            const bstarttime = data.bstarttime?moment(data.bstarttime):null;
            const bendtime =  data.bendtime?moment(data.bendtime):null;
            const evaluationstart_time = _data.evaluationstart_time?moment(_data.evaluationstart_time):null;
            const evaluationend_time =  _data.evaluationend_time?moment(_data.evaluationend_time):null;
            const {province_id,city_id,county_id} = _data;
            _data.bondPicker = [bstarttime,bendtime];
            _data.rangePicker = [bidstart_time,bidend_time];
            _data.evaluation = [evaluationstart_time,evaluationend_time];
            _data.position = province_id? [province_id,city_id,county_id] :'';
            _data.bond =  data.bond;
            _data.starting_price =  data.starting_price;
            console.log("_data",_data);
            _param.attachment_url = _data.attachment_url || [];

            setParams(_param);

            setEditor(BraftEditor.createEditorState(_data.content));
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);

    const [editor,setEditor] = useState();
    const excludeControls = ['media', 'emoji'];
    /** 富文本内容改变事件 */
    const handleEditorChange = (value) => {
        setEditor(value);
    };
    /** 提交验证 */
    const submitVerify = () => {
        form.validateFields().then((values) => {
            if (!editor.toHTML()) {
                return
            }
            showContactsVisible(true);
        })


    };
    let Loading = false;
    /** 提交事件 */
    const submit = ({is_approval = 0}) => {
        if (Loading) {
            message.warn('正在提交，不要心急哟~');
            return
        }
        const values =  form.getFieldsValue(['issued_name','bond',"address"]);
        console.log('======',values);
        let _params = Object.assign({...values}, {...params});
        _params.content = editor.toHTML();
        const [bidstart_time,bidend_time] =  form.getFieldValue('rangePicker') || [];
        const [bstarttime='',bendtime=''] =  form.getFieldValue('bondPicker') || [];
        const [evaluationstart_time='',evaluationend_time=''] = form.getFieldValue('evaluation') || [];
        _params.bidend_time = bidend_time ? bidend_time.format('YYYY-MM-DD HH:mm') : '';
        _params.bidstart_time = bidstart_time ? bidstart_time.format('YYYY-MM-DD HH:mm') : '';
        _params.bstarttime = bstarttime ? bstarttime.format('YYYY-MM-DD HH:mm') : '';
        _params.bendtime = bendtime ? bendtime.format('YYYY-MM-DD HH:mm') : '';
        _params.evaluationstart_time = evaluationstart_time ? evaluationstart_time.format('YYYY-MM-DD HH:mm') : '';
        _params.evaluationend_time = evaluationend_time ? evaluationend_time.format('YYYY-MM-DD HH:mm') : '';
        const [province_id,city_id,county_id] = form.getFieldValue('position');

        PublishModal.saveWriteFile({is_approval,project_sn,province_id,city_id,county_id, ..._params}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                Loading = false;
                if(is_approval === 0){
                    message.success('保存成功！',3);
                }else{
                    message.success('提交成功！',3, push({pathname: '/publish/listSend',state:{project_sn,step:3}}));
                };
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    /** 文件上传成功事件 */
    const handleFileChange = (val) => {
        let _params = params;
        _params.attachment_url = val;
        setParams(_params);
    };
    /** 审批人弹窗选择完成 */
    const handleContactsOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        showContactsVisible(false);
        const audit = users[0];

        PublishModal.setResponsible({
            type: AUDIT_SEND, project_sn, is_approval:1, ...audit
        }).then(({data: {data, msg, code}}) => {
            if (code === 200) {
                submit({is_approval: 1});
            } else {
                message.error(msg)
            }
        })
    };
    /** 人员选择回调函数 */
    const contactsSuccess = e => {
        setUsers(e.map(item => {
            return {username: item.title, user_id: item.id}
        }));
    };
    /** 关闭审批人弹窗 */
    const handleCancel = e => {
        showContactsVisible(false);
    };
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().subtract(1, 'days');
    }
    return (
        <div className={css.edit}>
            <div className={css.boxTitle}>
                招标文件
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>
            {initialData.isLoading &&
            <Form
                {...layout}
                initialValues={initialData}
                scrollToFirstError = { true }
                form={form}
            >
                <Form.Item
                    labelCol={{span: 3}}
                    wrapperCol={{span: 10}}
                    label="招标文件标题"
                    name="issued_name"
                    rules={[{required: true, message: '请填写招标文件标题'},{min: 6,message: '招标文件标题最少为6个字符'},{max: 50,message:'招标文件标题最长为50个字符'}]}
                >
                    <Input placeholder='请填写招标文件标题' autoComplete="off"/>
                </Form.Item>
                <Form.Item label="项目金额" wrapperCol={{span: 3}}>
                    {initialData.starting_price} (万元)
                </Form.Item>
                <div className={css.item}>


                    <Form.Item label="投标保证金" name="bond" wrapperCol={{span: 3}}
                       rules={[{required: true, message: '请填写投标保证金'}, ({getFieldValue})=>({
                        validator(rule,value){
                            if(value && value < 0){
                                return Promise.reject('保证金必须是正整数');
                            }
                            return Promise.resolve();
                        }
                    })]}>
                        <Input type={'number'} placeholder='投标保证金金额' suffix={'万元'}/>
                    </Form.Item>
                    <span className={css.msg} style={{left:'290px'}}><QuestionCircleOutlined style={{marginRight:'5px'}}/>保证金额不能超过项目金额的2%</span>
                </div>
                <div className={css.item}>
                    <Form.Item label="投标时间"  wrapperCol={{span: 10}}
                               rules={[{required: true, message: '请选择投标时间'}, ({ getFieldValue }) => ({
                                   validator(rule, value) {
                                       if(value && value[0] && value[1] && value[1].diff(value[0], 'minutes') < 20*24*60){
                                           return Promise.reject('投标时间间隔必须大于20天！');
                                       }
                                       return Promise.resolve();
                                   },
                               }),]}
                               name={'rangePicker'}
                    >
                        <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" locale={locale} disabledDate={disabledDate} normalize={(value,prevValue, prevValues)=> { console.log('v=====',value,prevValue, prevValues);value.format('YYYY-MM-DD HH:mm') }} style={{width: '100%'}}/>
                    </Form.Item>
                    <span className={css.msg}> <QuestionCircleOutlined style={{marginRight:'5px'}}/>投标时间间隔必须大于20天</span>
                </div>
                <div className={css.item}>
                    <Form.Item label="保证金缴纳时间"  wrapperCol={{span: 10}} name={'bondPicker'}  rules={[{required: true, message: '请选择保证金缴纳时间'}, ({ getFieldValue }) => ({
                        validator(rule, value) {
                            const rangePicker = form.getFieldValue('rangePicker');
                            console.log("rangePicker",rangePicker);
                            if(!rangePicker || !rangePicker[1]){
                                form.setFieldsValue({'bondPicker':null});
                                return Promise.reject('请先填写投标结束时间!');
                            }
                            if(value && rangePicker[1].diff( value[1], 'minutes') < 3*24*60){
                                return Promise.reject('保证金缴纳时间不能超过投标结束前三天！');
                            }
                            return Promise.resolve();
                        },
                    })]}>
                        <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" locale={locale} disabledDate={disabledDate} style={{width: '100%'}}/>
                    </Form.Item>
                    <span className={css.msg}><QuestionCircleOutlined style={{marginRight:'5px'}}/>保证金缴纳时间不能超过投标结束前三天</span>
                </div>
                <div className={css.item}>
                    <Form.Item label="评标时间"  wrapperCol={{span: 10}}
                               name={'evaluation'}
                               rules={[{required: true, message: '请选择评标时间'}, ({ getFieldValue }) => ({
                                   validator(rule, value) {
                                       if(value[1].get('date') !== value[0].get('date')){
                                           return Promise.reject('评标截止时间不得晚于评标当日的24点！');
                                       }
                                       return Promise.resolve();
                                   },
                               })]}
                    >
                        <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" locale={locale} disabledDate={disabledDate} style={{width: '100%'}}/>
                    </Form.Item>
                    <span className={css.msg}><QuestionCircleOutlined style={{marginRight:'5px'}}/>评标时间必须为投标截止之后，并且为同一天</span>
                </div>
                <Form.Item label="投标地点" name="position" required>
                    <AddressItem size="default" />
                </Form.Item>
                <Form.Item
                    wrapperCol={{span: 10,offset:3}}
                    name="address"
                    rules={[{required: true, message: '请填写投标地点详细地址'}]}
                >
                    <Input placeholder='详细地址' autoComplete="off"/>
                </Form.Item>

                <Form.Item label={'招标文件正文'} labelCol={{ span: 3}}
                           wrapperCol={{ span: 21 }} required>
                    <BraftEditor
                        excludeControls={excludeControls}
                        readOnly={readOnly}
                        value={editor}
                        onChange={handleEditorChange}
                        className={css.editor}
                        contentStyle={{height:'200px'}}
                        controlBarStyle={{display: readOnly? 'none' :''}}
                    />
                </Form.Item>

               <Form.Item labelCol={{ span: 3}} wrapperCol={{ offset:3,span: 12 }}>
                   <UploadFile  uploadNum={3} handleFileChange={handleFileChange} defaultFileList={params.attachment_url} />
               </Form.Item>
                <div className={css.btns}>
                    <Button size={ "large"} className={css.saveBtn}  onClick={ submit }>
                        保存
                    </Button>
                    <Button type="primary" size={"large"} onClick={ submitVerify } >
                        提交审批
                    </Button>
                </div>

                <Modal
                    title="选择审批人"
                    visible={contactsVisible}
                    onOk={handleContactsOk}
                    onCancel={handleCancel}
                >
                    <Contacts success={contactsSuccess} users={users}/>
                </Modal>
            </Form>
        }
        </div>);
}